import React from 'react';
import styled from 'styled-components';
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';
import { TransferHistory } from './Types/transferHistory';

const ContainerCard = styled('div')`
    display: flex;
    height: 270px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    border-bottom: 2px solid ${colors.grey400};
    background: ${colors.white};
`

const CardHeader = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`

const ChartContainer = styled('div')`
display: flex;
align-items: flex-start;
gap: 12px;
flex: 1 0 0;
align-self: stretch;
`

const YArea = styled('div')`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
`

const YAxis = styled('div')`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
flex: 1 0 0;
`

const ChartArea = styled('div')`
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: center;
gap: 8px;
flex: 1 0 0;
align-self: stretch;
height: 100%; /* Ensure the ChartArea takes up the full height */
`

const Bars = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%; /* Ensure bars take up the full width */
    height: 100%; /* Ensure bars take up the full height */
`;

interface BarProps {
    height: string;
    color: string;
}

const BarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex: 1; /* Ensure equal space for each bar */
    height: 100%; /* Ensure BarWrapper takes up full height */
    position: relative; /* Position for label alignment */
`;

const Bar = styled.div<BarProps>`
    width: 20px;
    border-radius: 6px;
    background-color: ${({ color }) => color};
    height: ${({ height }) => height}; /* Use the provided height */
    align-self: center; /* Ensure the bar is centered within the wrapper */
`;

const Label = styled(TypographyStyles.BodySRegular)`
    color: ${colors.grey800};
    margin-top: 8px; /* Space between bar and label */
`;



interface TransferDistributionCardProps {
    transferHistoryData: TransferHistory[];
}

const TransferDistributionCard: React.FC<TransferDistributionCardProps> = ({ transferHistoryData }) => {
    const calculateBarHeight = (percentage: number, maxPercentage: number) => {
        // Avoid division by zero
        if (maxPercentage === 0) return '0px';
        return `${(percentage / maxPercentage) * 100}%`;
    };

    const getBarColor = (index: number) => {
        if (index === 0 || index === 1) return colors.red; // First two bars
        if (index === 2) return colors.orange; // Next bar
        return colors.green; // Remaining bars
    };

    // Convert delay from seconds to minutes and bucket the data
    const buckets = [0, 0, 0, 0, 0, 0];
    transferHistoryData.forEach(entry => {
        const transferTime = entry.actualTransferTimeMinutes;

        // Handle possible null or undefined delay values
        if (transferTime != null) {

            if (transferTime <= 0) buckets[0] += 1;
            else if (transferTime <= 4) buckets[1] += 1;
            else if (transferTime <= 9) buckets[2] += 1;
            else if (transferTime <= 14) buckets[3] += 1;
            else if (transferTime <= 19) buckets[4] += 1;
            else buckets[5] += 1;
        }
    });

    const totalEntries = transferHistoryData.length;
    const percentages = buckets.map(count => (count / totalEntries) * 100);
    const maxPercentage = Math.max(...percentages) || 0; // Avoid issues with empty data

    // Calculate Y-axis labels
    const yAxisLabels = [
        maxPercentage.toFixed(0) + '%',
        (maxPercentage * 2 / 3).toFixed(0) + '%',
        (maxPercentage / 3).toFixed(0) + '%',
        '0%'
    ];

// Define X-axis labels with special cases for the first and last items
const xAxisLabels = percentages.map((_, index) => {
    if (index === 0) {
        return '<0';
    } else if (index === percentages.length - 1) {
        return '20+';
    } else {
        const start = (index - 1) * 5;
        const end = start + 4;
        return `${start}-${end}`;
    }
});

    return (
        <ContainerCard>
            <CardHeader>
                <TypographyStyles.BodyLSemibold color={colors.black}>
                    Transfer time distribution
                </TypographyStyles.BodyLSemibold>
                <TypographyStyles.BodyLRegular color={colors.grey600}>
                    (last 30 days)
                </TypographyStyles.BodyLRegular>
            </CardHeader>
            <ChartContainer>
                <YArea>
                    <YAxis>
                        {yAxisLabels.map((label, index) => (
                            <TypographyStyles.BodySRegular key={index} color={colors.grey600}>
                                {label}
                            </TypographyStyles.BodySRegular>
                        ))}
                    </YAxis>
                    <TypographyStyles.BodySRegular color={colors.grey800}>min</TypographyStyles.BodySRegular>
                </YArea>
                <ChartArea>
                    <Bars>
                        {percentages.map((percentage, index) => (
                            <BarWrapper key={index}>
                                <Bar
                                    height={calculateBarHeight(percentage, maxPercentage)}
                                    color={getBarColor(index)}
                                />
                                <Label>
                                    {xAxisLabels[index]}
                                </Label>
                            </BarWrapper>
                        ))}
                    </Bars>
                </ChartArea>
            </ChartContainer>
        </ContainerCard>
    );
};

export default TransferDistributionCard