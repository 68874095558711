import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from "../utils/colors";
import * as TypographyStyles from './TypographyStyles';

const Container = styled('div')`
  display: flex;
  padding: 64px 32px 0px 32px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`

const Body = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  align-self: stretch;
`


// Title styling
const Title = styled(TypographyStyles.PageTitle)`
color: ${colors.grey800};
text-align: center;
leading-trim: both;
text-edge: cap;
`;

// Subtitle styling
const Subtitle = styled(TypographyStyles.BodyXLRegular)`
  color: ${colors.grey800};
  text-align: center;
  leading-trim: both;
  text-edge: cap;
`;

// Message styling
const MessageText = styled(TypographyStyles.BodyMRegular)`
  color: ${colors.grey600};
  text-align: center;
  leading-trim: both;
  text-edge: cap;
`;

// Props interface with type property
interface MessageProps {
  type: 'loading' | 'error' | 'timeout' ;
  message: string | null;
}

// Define titles and subtitles based on the type
const getTitleAndSubtitle = (type: 'loading' | 'error' | 'timeout') => {
  switch (type) {
    case 'loading':
      return {
        title: 'Searching...',
        subtitle: '', // Subtitle will be managed dynamically
      };
    case 'error':
      return {
        title: 'Uh oh!',
        subtitle: 'We couldn’t find what you were looking for. Try searching with some different criteria.',
      };
    case 'timeout':
      return {
        title: 'Lost your journey :(',
        subtitle: `This page timed out and we lost the journey. It's a known issue we're working on. Meanwhile, go back to home and search again.`,
      };
    default:
      return {
        title: 'Oops!',
        subtitle: 'Something went wrong on our side.',
      };
  }
};

export const Message: React.FC<MessageProps> = ({ type, message }) => {
  const { title, subtitle: initialSubtitle } = getTitleAndSubtitle(type);
  const [ subtitle, setSubtitle] = useState<string>(initialSubtitle);

  useEffect(() => {
    let timer1: NodeJS.Timeout | null = null;
    let timer2: NodeJS.Timeout | null = null;

    if (type === 'loading') {
      // Initially, no subtitle
      setSubtitle('');
      // Set subtitle after 4 seconds
      timer1 = setTimeout(() => {
        setSubtitle('Hold on...');
      }, 4000);

      // Update subtitle after 8 seconds
      timer2 = setTimeout(() => {
        setSubtitle('This is a lot of data to process...');
      }, 8000);
    } else {
      // Immediately set subtitle for error type
      setSubtitle(initialSubtitle);
    }

    // Cleanup timeouts on unmount or when type changes
    return () => {
      if (timer1) clearTimeout(timer1);
      if (timer2) clearTimeout(timer2);
    };
  }, [type, initialSubtitle]);


  return (
    <Container>
      <Title>{title}</Title>
      <Body>
        <Subtitle>{subtitle}</Subtitle>
        <MessageText>{message}</MessageText>
      </Body>
    </Container>
  );
};

