import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { Leg } from "../../utils/mapJourneysData";
import * as TypographyStyles from '../TypographyStyles';
import { getColor } from "../../utils/colorFormatter";
import StatisticsBox from "../StatisticsBox";
import { colors } from "../../utils/colors";
import { TextButtonXS } from "../Controls/Buttons";

const BoxContent = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`

const StatisticsBody = styled('div')`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 6px 16px;
  flex: 1 0 0;
  flex-wrap: wrap;
`

type StatisticsProps = {
  leg: Leg;
}

const Statistics = ({ leg }: StatisticsProps) => {
  const navigate = useNavigate();
  
  const trainsCancelledPercentage = Math.round(Math.max(leg.arrDelay?.cancellationRate ?? 0, leg.depDelay?.cancellationRate ?? 0));
  const punctualityPercentage = Math.round(leg.arrDelay?.punctuality);
  const punctualityColor = isNaN(punctualityPercentage) ? colors.grey600 : getColor(punctualityPercentage, 'punctualityPercentage');

  const handleSeeMoreClick = () => {
    console.log("Navigating to train statistics from:", window.location.pathname);
    navigate('/train-statistics', { state: { from: window.location.pathname, leg: leg } });
  };

  return (
    <StatisticsBox>
      <BoxContent>
        <StatisticsBody>
          <TypographyStyles.BodySSemibold color={getColor(trainsCancelledPercentage, 'cancellationPercentage')}>
            {trainsCancelledPercentage}% 
            <TypographyStyles.BodySRegular 
              color={colors.grey800}> trains cancelled
            </TypographyStyles.BodySRegular>
          </TypographyStyles.BodySSemibold>
          <TypographyStyles.BodySSemibold color={punctualityColor}>
            {isNaN(punctualityPercentage) ? '--' : punctualityPercentage}% 
            <TypographyStyles.BodySRegular 
              color={colors.grey800}> arrive on time
            </TypographyStyles.BodySRegular>
          </TypographyStyles.BodySSemibold>
        </StatisticsBody>
        <TextButtonXS onClick={handleSeeMoreClick}>See more</TextButtonXS>
      </BoxContent>
    </StatisticsBox>
  )
}

export default Statistics;
