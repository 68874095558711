// LocationPicker.tsx
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import * as TypographyStyles from '../TypographyStyles';
import { colors } from '../../utils/colors'
import * as Buttons from '../Controls/Buttons';
import clearIcon from '../../icons/clear.svg';


// Interface for props
interface LocationPickerProps {
  onClose: () => void;
  onLocationSelect: (location: { name: string; id: string }) => void;
  recentlyChosen: Array<{ name: string; id: string }>;
}

// Interface for API location
interface APILocation {
  type: string;
  id: string | null;
  name: string;
}

// Styling components
const FrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 600px;
  max-height: 90%;
  padding-bottom: 12px;
  flex-direction: column;
  border-radius: 16px;
  background: ${colors.white};
  box-sizing: border-box; /* Ensure padding and borders are included in width calculation */
  max-height: 90%;
  overflow: hidden; /* Ensures no overflow from child elements */

  /* Drop shadow */
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.10);
`;

const TextFieldContainer = styled.div`
  display: flex;
  padding: 16px 24px 16px 16px;
  align-items: center;
  gap: 18px;
  align-self: stretch;
  position: relative; /* This is important for positioning the icon absolutely */
  box-sizing: border-box; /* Ensure padding and borders are included in width calculation */
  overflow: hidden; /* Ensures that overflowed content is clipped */
`

const CloseButton = styled(Buttons.TextButtonS)`
  flex-shrink: 0; /* Prevents the button from shrinking */
`;

const InputWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  max-width: calc(100% - 80px); /* Adjust based on the space needed for other elements */
`;

const Input = styled.input`
  font-size: 20px;
  font-weight: 400;
  font-family: 'DRPublikSemibold';
  padding: 9px 18px 9px 18px;
  border-radius: 8px;
  border: 2px solid ${colors.key};
  background: ${colors.white};
  flex: 1 1 auto; /* Allows the input to grow and shrink */
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ClearIconWrapper = styled.span<{ visible: boolean }>`
  display: ${props => (props.visible ? 'block' : 'none')};
  width: 20px;
  height: 20px;
  margin-left: -36px; /* Adjust as needed */
  margin-right: 16px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    fill: ${colors.black}; /* Apply the desired color */
  }
`;

const ListTitle = styled(TypographyStyles.BodyLSemibold)`
  display: flex;
  padding: 0px 24px 0px 34px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  margin: 0; /* Remove default margin */
`

const LocationList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LocationItem = styled.li`
  cursor: pointer;
  display: flex;
  padding: 14px 24px 14px 34px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  overflow: hidden;
  color: ${colors.grey800};
  leading-trim: both;

  text-edge: cap;
  text-overflow: ellipsis;

  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-family: 'DRPublikRegular';

  &:hover {
    background-color: ${colors.grey200};
  }
`;


const LocationPicker: React.FC<LocationPickerProps> = ({
  onClose,
  onLocationSelect,
  recentlyChosen,
}) => {
  const [query, setQuery] = useState('');
  const [suggestedLocations, setSuggestedLocations] = useState<
    Array<{ name: string; id: string }>
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  // Filter out duplicates from the recentlyChosen list
  const uniqueRecentlyChosen = Array.from(
      new Map(recentlyChosen.map(location => [location.id, location])).values()
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const clearInput = () => {
    setQuery('');
    setSuggestedLocations([]);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const fetchLocations = useCallback(async (query: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://v6.db.transport.rest/locations?query=${query}&results=6`
      );
      const data: APILocation[] = await response.json();
      const stopsAndStations = data
        .filter((location) => location.type === 'stop' || location.type === 'station')
        .map((location) => ({ name: location.name, id: location.id || '' }));
      setSuggestedLocations(stopsAndStations);
    } catch (error) {
      console.error('Error fetching locations:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query.length >= 3) {
        fetchLocations(query);
      } else {
        setSuggestedLocations([]);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [query, fetchLocations]);

    useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleLocationClick = (location: { name: string; id: string }) => {
    onLocationSelect(location);
    onClose();
  };

  return (
    <FrameContainer>
      <TextFieldContainer>
        <InputWrapper>
          <Input
            type="text"
            placeholder="Choose location"
            value={query}
            onChange={handleInputChange}
            ref={inputRef}
          />
          <ClearIconWrapper visible={!!query} onClick={clearInput}>
            <ReactSVG src={clearIcon} />
          </ClearIconWrapper>
        </InputWrapper>
        <CloseButton onClick={onClose}>Cancel</CloseButton>
      </TextFieldContainer>
      <div style={{ height: '14px' }} /> {/* 8px gap */}

      {isLoading && <ListTitle>Loading...</ListTitle>}
      
    {/* Conditional rendering for title */}
    {query.length < 3 && uniqueRecentlyChosen.length > 0 && (
      <>
        <ListTitle>Recents</ListTitle>
        <div style={{ height: '8px' }} /> {/* 8px gap */}
        <LocationList>
          {uniqueRecentlyChosen.map((location) => (
            <LocationItem
              key={location.id}
              onClick={() => handleLocationClick(location)}
            >
              {location.name}
            </LocationItem>
          ))}
        </LocationList>
      </>
    )}

    {query.length >= 3 && suggestedLocations.length > 0 && (
      <>
        <ListTitle>Suggested</ListTitle>
        <div style={{ height: '8px' }} /> {/* 8px gap */}
        <LocationList>
          {suggestedLocations.map((location) => (
            <LocationItem
              key={location.id}
              onClick={() => handleLocationClick(location)}
            >
              {location.name}
            </LocationItem>
          ))}
        </LocationList>
      </>
    )}
  </FrameContainer>
  );
};

export default LocationPicker;