import * as TypographyStyles from '../TypographyStyles';
import { Journey } from "../../utils/mapJourneysData";
import { colors } from "../../utils/colors";
import styled from "styled-components";
import { getColor } from "../../utils/colorFormatter";
import { getDurationInMinutes, formatDuration } from "../../utils/durationUtils";

type StatisticsProps = {
  journey: Journey;
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
`

const LineContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 4px 16px;
  align-self: stretch;
  flex-wrap: wrap;
`

const DelayStatsContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 4px 16px;
  align-self: stretch;
  flex-wrap: wrap;
`

const hideStats = (transferStats: any) => {
  if (!transferStats) return true
  if (
    (transferStats.averageTransferTime === null || transferStats.averageTransferTime === undefined) && 
    (transferStats.percentageMissedConnections === null || transferStats.percentageMissedConnections === undefined)
  ) return true
  return false
}

const getPlannedTransferTime = (transferIndex: number, journey: Journey): string => {
  const trainLegs = journey.legs.filter(leg => !leg.walking)
  const departure = trainLegs[transferIndex].plannedArrival
  const arrival = trainLegs[transferIndex+1].plannedDeparture
  return formatDuration(getDurationInMinutes(departure, arrival))
}

const Statistics = ({ journey }: StatisticsProps) => {
  const delayStats = journey.journeyDelayStats
  const destination = journey.legs[journey.legs.length - 1].destination.name;
  const numberOfTransfers = journey.transferStats.length

  return <Container>
    <DelayStatsContainer data-testid="delayStatsContainer" key="delayStatsContainer">
      {delayStats.averageDelayAtDest != null ? (
        <TypographyStyles.BodySRegular color={colors.grey800} data-testid="averageDelayAtDest">
          <TypographyStyles.BodySSemibold color={getColor(Math.round(delayStats.averageDelayAtDest), 'delayInMinutes')} >{delayStats.averageDelayAtDest >= 0 ? '+' : ''}{Math.round(delayStats.averageDelayAtDest)}m </TypographyStyles.BodySSemibold>
          at {destination}
        </TypographyStyles.BodySRegular>
      ) : null}
      {delayStats.journeyCancellationRate || delayStats.journeyCancellationRate === 0 ? (
        <TypographyStyles.BodySRegular color={colors.grey800}  data-testid="journeyCancellationRate">
          <TypographyStyles.BodySSemibold color={getColor(Math.round(delayStats.journeyCancellationRate), 'cancellationPercentage')} >{Math.round(delayStats.journeyCancellationRate)}% </TypographyStyles.BodySSemibold>
          trains cancelled
        </TypographyStyles.BodySRegular>
      ) : null}
    </DelayStatsContainer>
    {journey.transferStats.map((stat, index) => (
      <LineContainer key={`linesContainer-${index}`}>
        {numberOfTransfers > 1 && (
          <TypographyStyles.BodySRegular  color={colors.grey600} >Transfer {index+1}</TypographyStyles.BodySRegular>
        )}
        {
          hideStats(stat) ? (
            <TypographyStyles.BodySRegular  color={colors.grey600}>{getPlannedTransferTime(index, journey)} planned transfer time</TypographyStyles.BodySRegular>
          ) : (
            <>
              {stat.averageTransferTime != null ? (
                <TypographyStyles.BodySRegular  color={colors.grey800} >
                  <TypographyStyles.BodySSemibold  color={getColor(Math.round(stat.averageTransferTime), 'transferTimeInMinutes')} >{Math.round(stat.averageTransferTime)}m </TypographyStyles.BodySSemibold>
                  real transfer time
                </TypographyStyles.BodySRegular>
              ) : null}
              {stat.percentageMissedConnections != null ? (
                <TypographyStyles.BodySRegular  color={colors.grey800} >
                  <TypographyStyles.BodySSemibold  color={getColor(Math.round(stat.percentageMissedConnections), 'missedTransfersPercentage')} >{Math.round(stat.percentageMissedConnections)}% </TypographyStyles.BodySSemibold>
                  missed transfers
                </TypographyStyles.BodySRegular>
              ) : null}
            </>
          )
        }
      </LineContainer>  ))}
  </Container>
  
}

export default Statistics;