import chevron from '../../icons/Chevron.svg'
import styled from "styled-components";
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';


const BackContainer = styled('div')`
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  cursor: pointer;`

const ArrowButton = styled('div')`
  background-color: ${colors.key};
  border-radius: 50%;
  padding: 10px;
  display: inline-flex;
  box-sizing: border-box;
  & > img {
    width: 16px;
    height: 16px;
  }
`

type Props = {
  onClick: () => void;
  title: string;
}

const BackButton = ({ onClick, title }: Props) => {
  return (      
  <BackContainer onClick={onClick}>
    <ArrowButton>
      <img alt="backbutton" src={chevron} />
    </ArrowButton>
    <TypographyStyles.BodyLRegular color={colors.key}>{title}</TypographyStyles.BodyLRegular>
  </BackContainer>
)
}

export default BackButton;