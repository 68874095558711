import styled from "styled-components";
import * as TypographyStyles from '../TypographyStyles';
import { colors } from "../../utils/colors";
import stationbullet from '../../icons/stationbullet.svg'

const Container = styled('div')`
  display: flex;
  grid-column-gap: 8px;
  justify-content: space-between;
  width: 100%;
`

const Name = styled('div')`
  display: flex;
  margin-left: -32px;
  align-items: center;
  & > img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
`

type LocationProps = {
  name: string;
  platform?: string;
  plannedPlatform?: string;
}

const Location = ({ name, platform, plannedPlatform }: LocationProps) => {
  const platformToShow = platform ? platform : plannedPlatform;
  const platformHasChanged = platform ? platform !== plannedPlatform : false;
  return (
    <Container>
      <Name>
        <img alt="station" src={stationbullet} />
        <TypographyStyles.BodyLBold color={colors.black}>{name}</TypographyStyles.BodyLBold>
      </Name>
      {platformToShow && 
      <TypographyStyles.BodyMRegular color={platformHasChanged ? colors.orange : colors.grey600} size={14} weight={400}>platform 
        <TypographyStyles.BodyMSemibold 
          color={platformHasChanged ? colors.orange : colors.black} size={14} weight={600}> {platformToShow}
        </TypographyStyles.BodyMSemibold>
      </TypographyStyles.BodyMRegular>}
    </Container>
  )
}

export default Location;
