import { getColor } from "../utils/colorFormatter";
import * as TypographyStyles from './TypographyStyles';

type TimeUnit = 'minutes' | 'seconds';

type DelayIndicationProps = {
  delay: number;
  size?: number;
  weight?: number;
  color?: string;
  timeUnit?: TimeUnit;  // New prop to specify the time unit
}

const DelayIndication = ({ delay, size, weight, color, timeUnit = 'minutes' }: DelayIndicationProps) => {
  const isPositive = delay >= 0;
  const prefix = isPositive ? '+' : ''; // Add '+' only if the delay is positive

  // Convert delay to minutes if the time unit is seconds
  const delayInMinutes = timeUnit === 'seconds' ? Math.floor(delay / 60) : delay;
  const absoluteDelay = Math.abs(delayInMinutes); // Use absolute value to remove any negative sign in formatting

  const textStyle = {
    fontSize: size ? `${size}px` : 'inherit',
    fontWeight: weight ? weight : 'inherit',
    color: color || getColor(delayInMinutes, 'delayInMinutes')
  };

  if (absoluteDelay < 60) {
    return (
      <TypographyStyles.BodyLSemibold style={textStyle}
        color={color || getColor(delayInMinutes, 'delayInMinutes')}
      >
        {`${prefix}${delayInMinutes}m`}
      </TypographyStyles.BodyLSemibold>
    );
  }

  const hours = Math.floor(delayInMinutes / 60);
  const minutes = delayInMinutes % 60;

  if (minutes === 0) {
    return (
      <TypographyStyles.BodyLSemibold style={textStyle}
        color={color || getColor(delayInMinutes, 'delayInMinutes')}
      >
        {`${prefix}${hours}h`}
      </TypographyStyles.BodyLSemibold>
    );
  }

  return (
    <TypographyStyles.BodyLSemibold style={textStyle}
      color={color || getColor(delayInMinutes, 'delayInMinutes')}
    >
      {`${prefix}${hours}h ${minutes}m`}
    </TypographyStyles.BodyLSemibold>
  );
}

export default DelayIndication;