import styled from 'styled-components';
import { ReactComponent as OriginalIcon } from '../../icons/back.svg';
import { ReactComponent as LaterArrivalIcon } from '../../icons/down_next_arrow.svg';
import { ReactComponent as EarlierArrivalIcon } from '../../icons/down_previous_arrow.svg';
import { ReactComponent as LaterDepartureIcon } from '../../icons/up_next_arrow.svg';
import { ReactComponent as EarlierDepartureIcon } from '../../icons/up_previous_arrow.svg';
import { BodySRegular } from '../TypographyStyles';
import { colors } from '../../utils/colors'

const AlternativeButton = styled('button')<{ isLoading: boolean }>`
  display: inline-flex;
  align-items: end;
  gap: 4px; // Adjust the gap between icon and text
  color: ${colors.key}; // Text color from your theme
  background: none; // Remove button background
  border: none; // Remove border
  padding: 0; // Remove padding
  cursor: pointer; // Pointer cursor to indicate it's clickable
  leading-trim: both;
  text-edge: cap;

  svg {
    fill: ${colors.key}; // Icon color matching text color
    height: 12px;
    width: 12px;
  }

  &:hover {
    color: ${colors.keyDarker}; // Darker color on hover
    svg {
      fill: ${colors.keyDarker}; // Darken icon color on hover
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: ${colors.grey600}; // Disabled state color
    svg {
      fill: ${colors.grey600}; // Disabled icon color
    }
  }
`;

type ButtonProps = {
    onClick: () => void;
    isLoading?: boolean;
};

// Earlier Arrival Button
export const EarlierArrivalButton: React.FC<ButtonProps> = ({ onClick, isLoading = false }) => (
    <AlternativeButton onClick={onClick} isLoading={isLoading} disabled={isLoading}>
      <EarlierArrivalIcon />
      <BodySRegular>{isLoading ? 'Searching...' : 'Arrive earlier'}</BodySRegular>
    </AlternativeButton>
);

// Later Arrival Button
export const LaterArrivalButton: React.FC<ButtonProps> = ({ onClick, isLoading = false }) => (
    <AlternativeButton onClick={onClick} isLoading={isLoading} disabled={isLoading}>
      <LaterArrivalIcon />
      <BodySRegular>{isLoading ? 'Searching...' : 'Arrive later'}</BodySRegular>
    </AlternativeButton>
);

// Earlier Arrival Button
export const EarlierDepartureButton: React.FC<ButtonProps> = ({ onClick, isLoading = false }) => (
    <AlternativeButton onClick={onClick} isLoading={isLoading} disabled={isLoading}>
      <EarlierDepartureIcon />
      <BodySRegular>{isLoading ? 'Searching...' : 'Depart earlier'}</BodySRegular>
    </AlternativeButton>
);
  
// Later Departure Button
export const LaterDepartureButton: React.FC<ButtonProps> = ({ onClick, isLoading = false }) => (
<AlternativeButton onClick={onClick} isLoading={isLoading} disabled={isLoading}>
    <LaterDepartureIcon />
    <BodySRegular>{isLoading ? 'Searching...' : 'Depart later'}</BodySRegular>
</AlternativeButton>
);

// Later Departure Button
export const OriginalJourneyButton: React.FC<ButtonProps> = ({ onClick, isLoading = false }) => (
    <AlternativeButton onClick={onClick} isLoading={isLoading} disabled={isLoading}>
        <OriginalIcon />
        <BodySRegular>Back to original journey</BodySRegular>
    </AlternativeButton>
    );