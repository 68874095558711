import styled from 'styled-components';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { colors } from '../utils/colors'


// Styled wrapper for Calendar
const StyledCalendar = styled(Calendar)`
  border: none;
  width: 100%;

  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin-bottom: 28px;
    height: 100%;
  }

  .react-calendar__navigation button {
    margin: 0;
    padding: 0px;
    border-radius: 100px;
    height: 34px;
  }

  .react-calendar__navigation button:disabled {
    background-color: ${colors.white};
  }

  .react-calendar__navigation__label {
    font-family: 'DrPublikRegular';  
    color: ${colors.black};
    font-size: 20px; 
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none; /* Hide the year navigation buttons */
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    display: flex;
    min-width: 34px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${colors.grey600}; 
    border: 1px solid ${colors.grey600};
    cursor: pointer; /* Change cursor to pointer */
    }

  .react-calendar__navigation__prev-button:disabled,
  .react-calendar__navigation__next-button:disabled {
        color: ${colors.grey300};
        border-color: ${colors.grey300};
        cursor: not-allowed; /* Change cursor to not-allowed */
  }

  .react-calendar__navigation__prev-button svg,
  .react-calendar__navigation__next-button svg {
    height: 14px; /* Adjust to desired size */
  }

  .react-calendar__month-view__weekdays {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-family: 'DrPublikRegular';
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    color: ${colors.grey800};
    text-transform: lowercase; /* Convert all text to lowercase */
    &::first-letter {
      text-transform: capitalize; /* Capitalize the first letter */
    }
  }

  .react-calendar__month-view__days {
    display: grid  !important;
    grid-template-columns: repeat(7, 1fr); /* 7 equal-width columns */
    justify-items: center; /* Center items horizontally in their grid cells */
    row-gap: 8px;
  }

  .react-calendar__tile {
    font-family: 'DrPublikSemibold';
    font-size: 16px;
    border-radius: 50%;
    width: 36px; /* Adjust size as needed */
    height: 36px; /* Adjust size as needed */
    color: ${colors.grey800};
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; /* Ensure padding and border are included in width and height */
  }

  .react-calendar__tile:hover {
    background: ${colors.grey300};
    color: ${colors.black};
    border-radius: 100px;
  }

  .react-calendar__tile--active {
    background: ${colors.key};
    color: ${colors.white};
    border-radius: 100px;
  }

  .react-calendar__tile--now {
    background: ${colors.keyBackground};
    border-radius: 100px;
  }

  .react-calendar__tile--active.react-calendar__tile--now {
    background: ${colors.key};
    color: ${colors.white};
    border-radius: 100px;
  }

  .react-calendar__tile--disabled {
    color: ${colors.grey400};
    background: none;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export default StyledCalendar