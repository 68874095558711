import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { TransferHistory } from './Types/transferHistory';
import TransferHistoryCard from './TransferHistoryCard';
import TransferDistributionCard from './TransferDistributionCard';
import TransferTimeEvolutionCard from './TransferEvolutionCard';


const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 16px;
  box-sizing: border-box;
  align-items: left;
  gap: 32px;
`

// Define a type for delay history response if different from LineStatisticsResponse
type TransferHistoryResponse = TransferHistory[];

const TransferStatistics = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Extract both legs from location.state
    const firstLeg = location.state?.firstLeg;
    const secondLeg = location.state?.secondLeg;

    const [transferHistoryData, setTransferHistoryData] = useState<TransferHistoryResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!firstLeg || !secondLeg) return;

        const fetchData = async () => {
          try {
            setLoading(true);
    
            // Extract necessary details from both legs
            const { line: { name: firstLegLineName }, destination: { id: firstLegDestinationId } } = firstLeg;
            const { line: { name: secondLegLineName }, origin: { id: secondLegOriginId } } = secondLeg;

            const transferHistoryResponse = await fetch(
                `https://api.chuuchuu.com/transferhistory?firstLegLineName=${encodeURIComponent(firstLegLineName)}&firstLegDestinationId=${encodeURIComponent(firstLegDestinationId)}&secondLegLineName=${encodeURIComponent(secondLegLineName)}&secondLegOriginId=${encodeURIComponent(secondLegOriginId)}`
            );
    
            if (!transferHistoryResponse.ok) {
              throw new Error('Network response was not ok');
            }
    
            // Parse both responses
            const transferHistoryData: TransferHistoryResponse = await transferHistoryResponse.json();
    
            // Set state with the results
            setTransferHistoryData(transferHistoryData);

          } catch (err) {
            if (err instanceof Error) {
              setError(err.message);
            } else {
              setError('An unknown error occurred');
            }
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
    }, [firstLeg, secondLeg]);

    const handleBackClick = () => {
        const from = location.state?.from || '/';
        navigate(from); // Navigate back to the previous journey details page or the journeys overview if the state is missing
    };

  return (
    <Container>
        <PageHeader
            title={`Transfer statistics`}
            subtitle={`${firstLeg?.line?.name} ➜ ${secondLeg?.line?.name} at ${firstLeg.destination.name}`}
            backButtonTitle="Journey details"
            backButtonOnClick={handleBackClick} // Pass handleBackClick as prop if needed
        />
        {loading && <div>Loading...</div>}
        {error && <div>Error: {error}</div>}
        {transferHistoryData && (
            <TransferDistributionCard 
                transferHistoryData={transferHistoryData} 
            />
        )}
        {transferHistoryData && (
            <TransferTimeEvolutionCard 
                transferHistoryData={transferHistoryData} 
            />
        )}
        {transferHistoryData && (
            <TransferHistoryCard 
                transferHistoryData={transferHistoryData} 
            />
        )}
    </Container>
  );
};

export default TransferStatistics;