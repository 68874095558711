import styled from "styled-components";
import * as TypographyStyles from '../TypographyStyles';
import { colors } from "../../utils/colors";
import { getColor } from "../../utils/colorFormatter";

interface ContainerProps {
  score: number;
  completeness: string;
}

const Container = styled.div<ContainerProps>`
  display: inline-flex;
  flex-direction: row;
  width: min-content;
  grid-column-gap: 10px;
  background-color: ${props => (props.completeness === 'partial' || props.completeness === 'null') ? colors.white : getColor(props.score, 'chuuchuuScore')};
  color: ${props => (props.completeness === 'partial' || props.completeness === 'null') ? getColor(props.score, 'chuuchuuScore') : colors.white};
  padding: 2px 10px;
  border-radius: 6px;
  border: ${props => (props.completeness === 'partial' || props.completeness === 'null') ? `2px solid ${getColor(props.score, 'chuuchuuScore')}` : 'none'};
`

type ReliabilityScoreBadgeProps = {
  score?: number | null;
  completeness: string;
}

const ReliabilityScoreBadge = ({ score, completeness }: ReliabilityScoreBadgeProps) => {
  const displayScore = score != null ? Math.round(score) : -1; // Handle both null and undefined
  const displayText = displayScore < 0 ? 'n/a' : `${displayScore}${completeness === 'partial' ? '*' : ''}`;
  return (
    <Container score={displayScore} completeness={completeness}>
      <TypographyStyles.KPIValueSmall>{displayText}</TypographyStyles.KPIValueSmall>
      <TypographyStyles.KPITitle>chuuchuu<br />score™</TypographyStyles.KPITitle>
    </Container>
  )
}

export default ReliabilityScoreBadge;