import styled from "styled-components";
import statisticsIcon from '../icons/statisticsIcon.svg'
import * as TypographyStyles from './TypographyStyles';
import { colors } from "../utils/colors";
import { ReactSVG } from "react-svg";

const StatisticsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StatisticsTitle= styled('div')`
  display: flex;
  flex-direction: row;
  grid-column-gap: 6px;
  align-items: center;
  & svg {
    fill: ${colors.grey600};
  }
`

const StatisticsBody = styled('div')<{ color?: string }>`
  background-color: ${props=> props.color ? props.color : colors.grey200};
  padding: 12px;
  margin-top: 4px;
  border-radius: 8px;
  display: grid;
  grid-row-gap: 8px;
`

const StatisticsBox = ({ children, color }: any) => {
  return (
    <StatisticsContainer>
      <StatisticsTitle>
        <ReactSVG src={statisticsIcon} />
        <TypographyStyles.BodyXSRegular color={colors.grey600}>Statistics (last 30 days)</TypographyStyles.BodyXSRegular>
      </StatisticsTitle>
      <StatisticsBody color={color}>
        {children}
      </StatisticsBody>
    </StatisticsContainer>
  )
}

export default StatisticsBox;