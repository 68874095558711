export const getJourneyDurationInMinutes = (journeyLegs: any[]): number => {

  const originLeg = journeyLegs[0];
  const destinationLeg = journeyLegs[journeyLegs.length-1];

  const duration = new Date(destinationLeg.plannedArrival).getTime() - new Date(originLeg.plannedDeparture).getTime()
  const durationInMinutes = duration/60000

  return durationInMinutes;
}

export const getDurationInMinutes = (departure: string, arrival: string) => {
  const duration = new Date(arrival).getTime() - new Date(departure).getTime()
  const durationInMinutes = duration/60000

  return durationInMinutes;
}

export const formatDuration = (durationInMinutes: number | null | undefined): string => {
  if (durationInMinutes === null || durationInMinutes === undefined) {
    return 'N/A';
  }
  
  const hours = Math.floor(durationInMinutes / 60);          
  const minutes = (durationInMinutes % 60).toString()

  if (hours <= 0) return `${minutes}m`
  return `${hours}h${minutes}m`;
}