import styled from "styled-components";


type TagsProps = {
  tags: string[]
}

type TagSetting = {
  [key: string]: {
    string: string;
    color: string,
    textColor: string
  }
}

const tagsSettings: TagSetting = {
  'arrives-first': {
    string: 'Arrives first',
    color: '#F5D8FF',
    textColor: '#78398D'
  },
  'cheapest': {
    string: 'Cheapest',
    color: '#D8EAFF',
    textColor: '#315278'
  },
  'fastest': {
    string: 'Fastest',
    color: '#C3FACC',
    textColor: '#24622E'
  },
  'most-reliable': {
    string: 'Most reliable',
    color: '#FEFFD8',
    textColor: '#6F7121'
  },
  'direct': {
    string: 'Direct',
    color: '#FFD8D8',
    textColor: '#802525'
  },
  'least-transfers': {
    string: 'Least transfers',
    color: '#FFD8D8',
    textColor: '#802525'
  }
}

const TagsContainer = styled('div')`
  display: flex;
  grid-column-gap: 10px;
  padding: 0 12px;
  flex-direction: row;
  justify-content: flex-end;
`
type TagProps = { textColor: string; color: string }
const Tag = styled('div')<TagProps>`
  display: flex;
  background-color: ${props => props.color};
  color: ${props => props.textColor};
  font-family: 'DRPublikSemiBold'; // Test with direct values
  font-size: 16px;
  padding: 2px 8px;
  border-radius: 4px 4px 0px 0px;
`

const Tags = ({ tags }: TagsProps) => {
  return <TagsContainer>
    {tags.map((tag) => <Tag key={tag} color={tagsSettings[tag].color} textColor={tagsSettings[tag].textColor}>{tagsSettings[tag].string}</Tag>)}
  </TagsContainer>
}

export default Tags;