import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import * as TypographyStyles from './TypographyStyles';
import { colors } from "../utils/colors";
import BackButton from "./Controls/BackButton";


const Header = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
`

const TitleContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
`

type PageHeaderProps = {
    title: string;
    subtitle?: string;
    backButtonTitle?: string;
    backButtonOnClick?: () => void;
};

const PageHeader = ({ title, subtitle, backButtonTitle = "Back", backButtonOnClick }: PageHeaderProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleBackClick = () => {
        if (backButtonOnClick) {
          backButtonOnClick();
        } else {
          const from = location.state?.from || '/';
          navigate(from);
        }
    };

    return (
        <Header>
        <BackButton onClick={handleBackClick} title={backButtonTitle} />
        <TitleContainer>
            <TypographyStyles.PageTitle color={colors.black}>{title}</TypographyStyles.PageTitle>
            {subtitle && <TypographyStyles.PageSubtitle color={colors.grey600}>{subtitle}</TypographyStyles.PageSubtitle>}
        </TitleContainer>
        </Header>
    );
};

export default PageHeader;