import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { Journey } from "../../utils/mapJourneysData";
import { fetchJourneys } from '../../utils/fetchJourneys';
import { colors } from '../../utils/colors';

import * as AlternativeButtons from '../Controls/AlternativeButtons';
import JourneyLegCard from './JourneyLegCard';
import Transfer from "./Transfer";
import KPIsContainer from "./KPIsContainer";
import PageHeader from '../PageHeader';


const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  box-sizing: border-box;
  margin-top: 60px;
`

const LegsContainer = styled('div')`
  margin-top: 32px;
`

const TransferContainer = styled('div')`
  padding: 16px 16px 16px 24px;
  margin-left: 24px;
  position: relative;
  display: flex;
  grid-row-gap: 14px;
  flex-direction: column;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2px;
    background: repeating-linear-gradient(${colors.grey600}, ${colors.grey600} 6px, transparent 6px, transparent 12px);
  }
`

const AlternativesContainer = styled('div')`
  display: inline-flex;
  align-items: center;
  align-content: center;
  gap: 12px;
  flex-wrap: wrap;
`


type JourneyDetailsScreenProps = {
  journey: Journey;
}

// Define the fetchLaterDepartures function
const fetchLaterDepartures = (
  originId: string, 
  destinationId: string, 
  plannedDeparture: string, 
  departure: string,
  onJourneysFetched: (journeys: any[], newEarlierThan: string | null, newLaterThan: string | null, newDepartureTime: string) => void, // Pass newDepartureTime here
  onError: (message: string) => void,
  onLoadingChange: (isLoading: boolean) => void
) => {
  console.log("Fetching later departures with params:");

  // Determine the correct departure time
  const departureTimeStr = departure || plannedDeparture; // Use `departure` if available, otherwise use `plannedDeparture`

  // Parse the departureTime and add 1 minute
  const departureTime = new Date(departureTimeStr);
  departureTime.setMinutes(departureTime.getMinutes() + 1);

  // Format the updated departureTime as an ISO string (you can format it based on the API requirements)
  const newDepartureTime = departureTime.toISOString();

  console.log("New Departure Time:", newDepartureTime);

  // Call fetchJourneys with the correct parameters
  fetchJourneys({
    type: 'initial',
    origin: originId,
    destination: destinationId,
    date: newDepartureTime,
    tripType: 'departure', 
    options: {}, // If you have options, you can pass them here
    earlierThan: null, // No earlier journeys needed
    laterThan: null,  // No later journeys needed
    onJourneysFetched: (journeys, newEarlierThan, newLaterThan) => onJourneysFetched(journeys, newEarlierThan, newLaterThan, newDepartureTime),  // Pass the newDepartureTime to onJourneysFetched
    onError: onError,  // Callback for error handling
    onLoadingChange: onLoadingChange,  // Callback to show/hide loading indicator
  });
}

// Define the fetchLaterDepartures function
const fetchEarlierArrivals = (
  originId: string, 
  destinationId: string, 
  plannedArrival: string, 
  arrival: string,
  onJourneysFetched: (journeys: any[], newEarlierThan: string | null, newLaterThan: string | null, newArrivalTime: string) => void, // Pass newArrivalTime here
  onError: (message: string) => void,
  onLoadingChange: (isLoading: boolean) => void
) => {
  console.log("Fetching earlier arrivals with params:");

  // Determine the correct departure time
  const arrivalTimeStr = arrival || plannedArrival; // Use `arrival` if available, otherwise use `plannedArrival`

  // Parse the departureTime and add 1 minute
  const arrivalTime = new Date(arrivalTimeStr);
  arrivalTime.setMinutes(arrivalTime.getMinutes() - 1);

  // Format the updated departureTime as an ISO string (you can format it based on the API requirements)
  const newArrivalTime = arrivalTime.toISOString();

  console.log("New Arrival Time:", newArrivalTime);

  // Call fetchJourneys with the correct parameters
  fetchJourneys({
    type: 'initial',
    origin: originId,
    destination: destinationId,
    date: newArrivalTime,
    tripType: 'arrival', 
    options: {}, // If you have options, you can pass them here
    earlierThan: null, // No earlier journeys needed
    laterThan: null,  // No later journeys needed
    onJourneysFetched: (journeys, newEarlierThan, newLaterThan) => onJourneysFetched(journeys, newEarlierThan, newLaterThan, newArrivalTime),  // Pass the newArrivalTime to onJourneysFetched
    onError: onError,  // Callback for error handling
    onLoadingChange: onLoadingChange,  // Callback to show/hide loading indicator
  });
}

const JourneyDetailsScreen = ({ journey }: JourneyDetailsScreenProps) => {
  const [isLoading, setIsLoading] = useState(false); // State for button loading
  const [currentLegs, setCurrentLegs] = useState(journey.legs); // State for current legs of the journey (could be original or alternative)
  const [alternativeLegs, setAlternativeLegs] = useState<Journey["legs"] | null>(null); // State to store the alternative legs
  const [showOriginal, setShowOriginal] = useState(true); // Toggle between showing original or alternative journey
  const navigate = useNavigate(); // Initialize useNavigate

  const originalLegs = journey.legs;

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component is mounted
  }, []);
  
  const origin = currentLegs[0].origin.name;
  const destination = currentLegs[currentLegs.length - 1].destination.name;
  const departure = new Intl.DateTimeFormat('en-GB', {
    weekday: "long",
    month: "numeric",
    day: "numeric",
  }).format(new Date(currentLegs[0].departure))

  const handleBackClick = () => {
    navigate('/'); // Navigate back to the journeys overview
  };

  // Log when currentLegs changes
  useEffect(() => {
    console.log("Updated current legs:", currentLegs);
  }, [currentLegs]);  // This will trigger every time currentLegs changes

  // Define onJourneysFetched
  const onJourneysFetched = (
    journeys: any[], 
    newEarlierThan: string | null, 
    newLaterThan: string | null,
    newTime: string, // newDepartureTime or newArrivalTime
    searchType: "departure" | "arrival" // Determine search type
  ) => {
    // Filter journeys based on the first leg's departure time
    const filteredJourneys = journeys.filter(journey => {
      const firstLeg = journey.legs[0];
      const lastLeg = journey.legs[journey.legs.length - 1];

      if (searchType === "departure") {
        const firstLegDeparture = firstLeg.departure || firstLeg.plannedDeparture;
        return new Date(firstLegDeparture) >= new Date(newTime); // Filter later departures
      } else if (searchType === "arrival") {
        const lastLegArrival = lastLeg.arrival || lastLeg.plannedArrival;
        return new Date(lastLegArrival) <= new Date(newTime); // Filter earlier arrivals
      }
      return false;
    });

    if (filteredJourneys.length > 0) {
      // Determine whether to use the first or last journey based on searchType
      const newLegs = searchType === "departure"
        ? filteredJourneys[0].legs // Use the first journey for later departure
        : filteredJourneys[filteredJourneys.length - 1].legs; // Use the last journey for earlier arrival

      setCurrentLegs(currentLegs => {
        
        // Find the search point index in the original legs
        const searchPointIndex = searchType === "departure"
          ? currentLegs.findIndex(leg => leg.origin.id === newLegs[0].origin.id) // Compare origins for later departures
          : currentLegs.findIndex(leg => leg.destination.id === newLegs[newLegs.length - 1].destination.id); // Compare destinations for earlier arrivals
  
        // If the search point is found, combine legs
        if (searchPointIndex !== -1) {
          // Create the combined journey
          const combinedLegs = searchType === "departure"
            ? [
                ...currentLegs.slice(0, searchPointIndex), // Original legs up to the search point
                ...newLegs // New legs from the alternative journey (later departure)
              ]
            : [
                ...newLegs, // New legs from the alternative journey (earlier arrival)
                ...currentLegs.slice(searchPointIndex + 1) // Original legs after the search point
              ];
  
          setAlternativeLegs(combinedLegs); // Store the combined legs
          setShowOriginal(false); // Show alternative journey
          return combinedLegs;  // Set combined legs as the new current legs
        }
  
        return currentLegs; // Return original legs if no search point is found
      });
    }
  };

  // Define onError
  const onError = (message: string) => {
    console.error("Error fetching journeys:", message);
  };

  // Define onLoadingChange
  const onLoadingChange = (isLoading: boolean) => {
    setIsLoading(isLoading); // Toggle the loading state
  };

  const handleSearchClick = (index: number, searchType: "departure" | "arrival") => {
    const nextLeg = trainLegs[index + 1];
    const prevLeg = trainLegs[index]; // Previous leg for earlier arrival
    
    if (searchType === "departure" && nextLeg) {
      const originId = nextLeg.origin.id;
      const destinationId = trainLegs[trainLegs.length - 1].destination.id;
      const plannedDeparture = nextLeg.plannedDeparture;
      const departure = nextLeg.departure;
  
      fetchLaterDepartures(
        originId,
        destinationId,
        plannedDeparture,
        departure,
        (journeys, newEarlierThan, newLaterThan, newDepartureTime) => 
          onJourneysFetched(journeys, newEarlierThan, newLaterThan, newDepartureTime, "departure"),
        onError,
        onLoadingChange
      );
    } else if (searchType === "arrival" && prevLeg) {
      const originId = trainLegs[0].origin.id; // Origin from first leg
      const destinationId = prevLeg.destination.id;
      const plannedArrival = prevLeg.plannedArrival;
      const arrival = prevLeg.arrival;
  
      fetchEarlierArrivals(
        originId,
        destinationId,
        plannedArrival,
        arrival,
        (journeys, newEarlierThan, newLaterThan, newArrivalTime) => 
          onJourneysFetched(journeys, newEarlierThan, newLaterThan, newArrivalTime, "arrival"),
        onError,
        onLoadingChange
      );
    }
  };

  const handleToggleJourney = () => {
    setShowOriginal(!showOriginal); // Toggle between showing original and alternative journey
  };

  // Determine which legs to show (original or alternative)
  const legsToShow = showOriginal ? originalLegs : alternativeLegs || currentLegs;

  // Filter out walking legs
  const trainLegs = legsToShow.filter(leg => !leg.walking)

  return (
    <Container>
      <PageHeader
            title={`${origin} ➜ ${destination}`}
            subtitle={`${departure}`}
            backButtonTitle="Journeys"
            backButtonOnClick={handleBackClick} // Pass handleBackClick as prop if needed
      />
      <KPIsContainer journey={journey} />
      <LegsContainer>
        {trainLegs.map((leg, index) => (
          <React.Fragment key={index}>
            <JourneyLegCard leg={leg} />
              {index < trainLegs.length - 1 ? (
                <>
                  <TransferContainer>
                    <AlternativesContainer>
                      <AlternativeButtons.EarlierArrivalButton
                        onClick={() => handleSearchClick(index, "arrival")}
                        isLoading={isLoading} 
                      />
                      {!showOriginal && !isLoading && (
                        <AlternativeButtons.OriginalJourneyButton 
                          onClick={handleToggleJourney} // Toggle back to the original journey
                        />
                      )}
                    </AlternativesContainer>

                    <Transfer index={index} journey={{ ...journey, legs: trainLegs }} />
                    
                    <AlternativesContainer>
                      <AlternativeButtons.LaterDepartureButton
                        onClick={() => handleSearchClick(index, "departure")}
                        isLoading={isLoading} 
                      />
                      {!showOriginal && !isLoading && (
                        <AlternativeButtons.OriginalJourneyButton 
                          onClick={handleToggleJourney} // Toggle back to the original journey
                        />
                      )}
                    </AlternativesContainer>
                  </TransferContainer>
                </>
              ) : null}
          </React.Fragment>
        ))}
      </LegsContainer>
    </Container>
  )
}

export default JourneyDetailsScreen;
