import { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as TypographyStyles from '../TypographyStyles';
import { ReactSVG } from 'react-svg';
import calendarIcon from '../../icons/calendar.svg';
import controlsIcon from '../../icons/controls.svg';
import upDownArrowsIcon from '../../icons/updownarrows.svg';
import trainIcon from '../../icons/train.svg';
import { format, isToday, isTomorrow, parseISO } from 'date-fns';
import { colors } from '../../utils/colors'

// Import overlay components
import LocationPicker from './LocationPicker';
import DateTimeCard from './DateTimeCard';
import OptionsCard from './OptionsCard';

// Container for the entire component
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  padding: 24px 16px; // Add 24px padding at the top and bottom
  position: relative;  // To position the switch button absolutely within this container
  background: ${colors.white};
  border-bottom: 2px solid ${colors.keyHover};
`;

// Wrapper for the origin and destination buttons
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
`;

// Common styles for buttons
const LocationButton = styled.button`
  display: flex;
  flex-direction: column;
  padding: 11px 18px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: var(--Key-colours-Button-fill, rgba(151, 71, 255, 0.10));
  border: none;
  cursor: pointer;
`;

// Specific styles for the switch button
const SwitchButton = styled.button`
  display: flex;
  width: 36px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 27px;
  top: 54px;
  border-radius: 100px;
  background: ${colors.keyHover};
  border: none;
  cursor: pointer;
`;

// Wrapper for DateTime and Options buttons
const ControlsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-top: 12px;
`;

// Styles for DateTime button
const DateTimeButton = styled.button`
  display: flex;
  padding: 12px 18px;
  align-items: center;  // Align items vertically centered
  gap: 8px;
  border-radius: 8px;
  background: var(--Key-colours-Button-fill, rgba(151, 71, 255, 0.10));
  border: none;
  cursor: pointer;
  flex: 1 1 auto;  // Makes the button grow to fill available horizontal space
`;

// Styles for Options button
const OptionsButton = styled.button`
  display: flex;
  padding: 12px 18px;
  align-items: center;  // Align items vertically centered
  gap: 8px;
  border-radius: 8px;
  background: var(--Key-colours-Button-fill, rgba(151, 71, 255, 0.10));
  border: none;
  cursor: pointer;
  flex: 0 0 auto;  // Button width hugs its content
`;

const SearchButton = styled.button`
  margin-top: 24px;
  display: flex;
  padding: 17px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid ${colors.keyDarker};
  background: ${colors.keyBackground};
  
  &:hover {
    border: 2px solid ${colors.key};
    background: ${colors.white};
  }
`;

// Wrapper for label and location name
const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;  // 8px gap between label and location name
  width: 100%;  // Ensures full width for text overflow handling
`;

// Typography styles for labels
const LabelText = styled(TypographyStyles.BodyXLRegular)`
  color: ${colors.key};
`;

// Typography styles for location names
const LocationText = styled(TypographyStyles.BodyXLSemibold)`
  color: ${colors.keyDarkest};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
`;

// Typography styles for Control button text
const ControlText = styled(TypographyStyles.BodyLRegular)`
  color: ${colors.keyDarker};
  text-align: center;  // Center-align text in the button
`;

// Container for time and date text in ControlButton
const DateTimeText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;  // Gap between time and date
  width: 100%;  // Ensures the text container fills the button
`;

// Typography styles for location names
const SearchButtonText = styled(TypographyStyles.ButtonTextLarge)`
  color: ${colors.keyDarkest};
  leading-trim: both;
  text-edge: cap;
  line-height: 24px; /* 85.714% */
`;

// Icon styles for control buttons
const ControlIcon = styled.div`
  width: 20px;
  height: 19px;
  svg {
    width: 100%;
    height: 100%;
    fill: ${colors.key};
    display: block; 
  }
`;

// Icon styles for switch button
const SwitchIcon = styled.div`
  width: 16px;
  height: 16px;
  svg {
    width: 100%;
    height: 100%;
    fill: ${colors.keyDarker};
    display: block; 
  }
`;

// Icon styles for search button
const SearchIcon = styled.div`
  height: 24px;
  svg {
    width: 100%;
    height: 100%;
    fill: ${colors.keyDarkest};
    display: block; 
  }
`;

// Overlay styling
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

interface SearchControlsProps {
  setOrigin: (id: string) => void;
  setDestination: (id: string) => void;
  setDate: (date: string) => void;
  setTripType: (type: 'departure' | 'arrival') => void;
  setOptions: (options: Record<string, string>) => void;
  fetchJourneys: (type: 'earlier' | 'later' | 'initial') => void;  // Updated to match the function
}

const defaultOptions: Record<string, string> = {
  transfers: '-1',
  transferTime: '0',
  accessibility: 'none',
  bike: 'false',
  longdistance: 'true',
  regional: 'true',
  suburban: 'true',
  local: 'true',
};

const SearchControls = ({ setOrigin, setDestination, setDate, setTripType, setOptions, fetchJourneys }: SearchControlsProps) => {
  // Initialize state from localStorage or default to an empty string
  const [origin, setOriginLocation] = useState(localStorage.getItem('originName') || '');
  const [originId, setOriginId] = useState(localStorage.getItem('originId') || '');
  const [destination, setDestinationLocation] = useState(localStorage.getItem('destinationName') || '');
  const [destinationId, setDestinationId] = useState(localStorage.getItem('destinationId') || '');
  
  const [isLocationPickerOpen, setLocationPickerOpen] = useState(false);
  const [locationType, setLocationType] = useState<'from' | 'to'>('from');
  const [recentlyChosen, setRecentlyChosen] = useState<{ name: string; id: string }[]>(
    JSON.parse(localStorage.getItem('recentlyChosen') || '[]')
  );  

  const [isDateTimeCardOpen, setDateTimeCardOpen] = useState(false);
  const [chosenTime, setChosenTime] = useState(localStorage.getItem('chosenTime') || '');
  const [chosenDate, setChosenDate] = useState(localStorage.getItem('chosenDate') || '');
  const [departOrArrive, setDepartOrArrive] = useState<'departure' | 'arrival'>(
    (localStorage.getItem('departOrArrive') as 'departure' | 'arrival') || 'departure'
  );  const [isOptionsCardOpen, setOptionsCardOpen] = useState(false);

  const [appliedOptions, setAppliedOptions] = useState<Record<string, string>>(() => {
    // Initialize state from localStorage or default to defaultOptions
    const options: Record<string, string> = {};
    Object.keys(defaultOptions).forEach((key) => {
      const savedOption = localStorage.getItem(`option_${key}`);
      options[key] = savedOption || defaultOptions[key];
    });
    return options;
  });

  useEffect(() => {
    // Save settings to localStorage whenever they change
    localStorage.setItem('originName', origin);
    localStorage.setItem('originId', originId);
    localStorage.setItem('destinationName', destination);
    localStorage.setItem('destinationId', destinationId);
    localStorage.setItem('recentlyChosen', JSON.stringify(recentlyChosen));
    localStorage.setItem('chosenTime', chosenTime);
    localStorage.setItem('chosenDate', chosenDate);
    localStorage.setItem('departOrArrive', departOrArrive);
    // Save each option individually
    Object.keys(appliedOptions).forEach((key) => {
      localStorage.setItem(`option_${key}`, appliedOptions[key]);
    });
  }, [origin, originId, destination, destinationId, recentlyChosen, chosenTime, chosenDate, departOrArrive, appliedOptions]);

  const openOverlay = (type: 'from' | 'to') => {
    setLocationType(type);
    setLocationPickerOpen(true);
  };

  const closeOverlay = () => {
    setLocationPickerOpen(false);
    setOptionsCardOpen(false);
    setDateTimeCardOpen(false);
  };

  const handleLocationSelect = (location: { name: string; id: string }) => {
    setRecentlyChosen((prev) => [location, ...prev].slice(0, 10));

    // Update origin or destination ID based on the active locationType
    if (locationType === 'from') {
      setOrigin(location.id);
      setOriginLocation(location.name);
      setOriginId(location.id);
    } else if (locationType === 'to') {
      setDestination(location.id);
      setDestinationLocation(location.name);
      setDestinationId(location.id);
    }

    closeOverlay();
  };

  const handleSwitchLocations = () => {
    // Use temporary variables to swap names and ids
    const tempName = origin;
    const tempId = originId;

    // Swap the location names
    setOriginLocation(destination);
    setDestinationLocation(tempName);

    // Swap the location ids
    setOrigin(destinationId);
    setDestination(tempId);

    // Also update the internal IDs used for swapping
    setOriginId(destinationId);
    setDestinationId(tempId);
  };

  // Function to handle the date and time application
  const handleDateTime = (dateTime: string, type: 'departure' | 'arrival') => {
    // Update state in parent component
    setDate(dateTime);      // Set the date in App component
    setTripType(type);      // Set the trip type in App component
  
    // Check if dateTime has a valid date and time part
    const datePart = dateTime.includes('T') ? dateTime.split('T')[0] : dateTime || '';  // Get date part if it exists
    const timePart = dateTime.includes('T') ? dateTime.split('T')[1] : '';  // Get time part if it exists
    
    const parsedDate = datePart ? format(parseISO(datePart), 'yyyy-MM-dd') : '';  // Parse the date part if it exists
    
    setChosenDate(parsedDate); // Save the chosen date, or an empty string if no date was selected
    setChosenTime(timePart); // Save the chosen time, or an empty string if no time was selected
    setDepartOrArrive(type); // Save the depart or arrive type
    setDateTimeCardOpen(false); // Optionally close the overlay after applying
  };

  // Format the date string
  const getFormattedDate = () => {
    if (!chosenDate) return ''; // Default text
    const date = parseISO(chosenDate);
    if (isToday(date)) return 'today';
    if (isTomorrow(date)) return 'tomorrow';
    return format(date, 'dd/MM');
  };

  // Function to handle the application of options
  const handleOptions = (options: Record<string, string>) => {
    setOptions(options); // Set options as an object
    console.log(options);
    setAppliedOptions(options); // Update local state
    setOptionsCardOpen(false);
  };

  return (
    <Container>
      <ButtonWrapper>
        <LocationButton onClick={() => openOverlay('from')}>
          <LabelWrapper>
            <LabelText>From</LabelText>
            <LocationText>{origin}</LocationText>
          </LabelWrapper>
        </LocationButton>
        <LocationButton onClick={() => openOverlay('to')}>
          <LabelWrapper>
            <LabelText>To</LabelText>
            <LocationText>{destination}</LocationText>
          </LabelWrapper>
        </LocationButton>
        <SwitchButton onClick={handleSwitchLocations}>
          <SwitchIcon>
            <ReactSVG src={upDownArrowsIcon} />
          </SwitchIcon>
        </SwitchButton>
      </ButtonWrapper>
      <ControlsWrapper>
        <DateTimeButton onClick={() => setDateTimeCardOpen(true)}>
          <ControlIcon>
            <ReactSVG src={calendarIcon} />
          </ControlIcon>
          <DateTimeText>
            <ControlText>
              {chosenTime ? (
                `${departOrArrive === 'arrival' ? 'Arrive' : 'Depart'} ${getFormattedDate()} at ${chosenTime}`
              ) : (
                'Depart now'
              )}
            </ControlText>
          </DateTimeText>
        </DateTimeButton>
        <OptionsButton onClick={() => setOptionsCardOpen(true)}>
          <ControlIcon>
            <ReactSVG src={controlsIcon} />
          </ControlIcon>
          <ControlText>
            <ControlText>Options</ControlText>
          </ControlText>
        </OptionsButton>
      </ControlsWrapper>
      <SearchButton onClick={() => fetchJourneys('initial')}>
          <SearchIcon>
          <ReactSVG src={trainIcon} />
        </SearchIcon>
        <SearchButtonText>
          <SearchButtonText>Find reliable trains</SearchButtonText>
        </SearchButtonText>
      </SearchButton>
      {(isLocationPickerOpen || isOptionsCardOpen || isDateTimeCardOpen) && (
        <Overlay>
          {isLocationPickerOpen && (
            <LocationPicker
              onClose={closeOverlay}
              onLocationSelect={handleLocationSelect}
              recentlyChosen={recentlyChosen}
            />
          )}
          {isOptionsCardOpen && (
            <OptionsCard
            onClose={closeOverlay}
            onApply={handleOptions}
            initialOptions={appliedOptions}
            />
          )}
          {isDateTimeCardOpen && (
            <DateTimeCard 
            onClose={closeOverlay} 
            onApply={handleDateTime} 
            initialDate={chosenDate}
            initialTime={chosenTime}
            initialType={departOrArrive}            
            />
          )}        
        </Overlay>
      )}
    </Container>
  );
};

export default SearchControls;