import dayjs from 'dayjs';
import React from 'react';
import { ComposedChart, Bar, XAxis, YAxis, Line, Tooltip, TooltipProps, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';
import { DelayHistory } from './Types/delayHistory';

const ContainerCard = styled('div')`
    display: flex;
    height: 270px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    border-bottom: 2px solid ${colors.grey400};
    background: ${colors.white};
`

const CardHeader = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`

const ChartContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
`

const LegendContainer = styled('div')`
    display: flex;
    align-items: end;
    gap: 8px;
`

const KeyDot = styled('div')`
    width: 12px;
    height: 12px;
    background: linear-gradient(180deg, ${colors.key} 0%, #5B2B99 100%);
    border-radius: 50%; // Make it circular if that's desired
`;

// Define styled component for tooltip
const CustomTooltipContainer = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey400};
  border-radius: 8px; /* Rounded corners */
  padding: 10px;
  font-size: 14px;
  color: ${colors.grey800};
`;

const CustomTooltip: React.FC<TooltipProps<any, any>> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { delay, movingAverage } = payload[0].payload;
  
      return (
        <CustomTooltipContainer>
          <div><TypographyStyles.BodyMSemibold>Date:</TypographyStyles.BodyMSemibold> {label}</div>
          <div><TypographyStyles.BodyMSemibold>Delay:</TypographyStyles.BodyMSemibold> {delay != null ? `${Math.round(delay)} min` : 'N/A'}</div>
          <div><TypographyStyles.BodyMSemibold>Moving average:</TypographyStyles.BodyMSemibold> {movingAverage != null ? `${Math.round(movingAverage)} min` : 'N/A'}</div>
        </CustomTooltipContainer>
      );
    }
  
    return null;
  };


interface DelayEvolutionCardProps {
    delayHistoryData: DelayHistory[];
    selectedOption: 'origin' | 'destination';
}

interface DataPoint {
    date: string;
    delay: number | null; // Allow null values
    [key: string]: any; // For any additional properties in the data points
}

const DelayEvolutionCard: React.FC<DelayEvolutionCardProps> = ({ delayHistoryData, selectedOption }) => {
    
    const today = dayjs();
    const days = Array.from({ length: 30 }, (_, i) => today.subtract(i, 'day').format('YYYY-MM-DD')).reverse();

    const data = days.map(day => {
        // Filter entries for the current day
        const relevantEntries = delayHistoryData.filter(entry => {
            const dateField = selectedOption === 'origin' ? entry.plannedDeparture : entry.plannedArrival;
            return dayjs(dateField).format('YYYY-MM-DD') === day;
        });
        
        // Calculate total delay, ignoring null values
        const totalDelay = relevantEntries.reduce((acc, entry) => {
            const delay = selectedOption === 'origin' ? entry.departureDelay : entry.arrivalDelay;
            if (delay != null) {
                const delayInMinutes = Math.floor(delay / 60);
                return acc + delayInMinutes;
            }
            return acc; // Skip adding if delay is null
        }, 0);
    
        // If there are no valid delays, set delay to null
        const finalDelay = relevantEntries.some(entry => {
            const delay = selectedOption === 'origin' ? entry.departureDelay : entry.arrivalDelay;
            return delay != null;
        }) ? totalDelay : null;
        
        return { date: dayjs(day).format('D/M'), delay: finalDelay };
    });


    const calculateMovingAverage = (data: DataPoint[], windowSize: number): DataPoint[] => {
        return data.map((item, index, array) => {
            // Include only non-null delays in the moving average window
            const window = array.slice(Math.max(0, index - windowSize + 1), index + 1)
                .filter(point => point.delay != null);
    
    
            const sum = window.reduce((total, currentItem) => total + (currentItem.delay || 0), 0);
            const average = window.length > 0 ? sum / window.length : null; // Handle empty window
        
            return {
                ...item,
                movingAverage: average,
            };
        });
    };
    
    const movingAverageData = calculateMovingAverage(data, 14);

    return (
        <ContainerCard>
            <CardHeader>
                <TypographyStyles.BodyLSemibold color={colors.black}>
                    Delay evolution
                </TypographyStyles.BodyLSemibold>
                <TypographyStyles.BodyLRegular color={colors.grey600}>
                    (last 30 days)
                </TypographyStyles.BodyLRegular>
            </CardHeader>
            <ChartContainer>
                <TypographyStyles.BodySRegular color={colors.grey600}>min</TypographyStyles.BodySRegular>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart data={movingAverageData} margin={{ top: 0, right: 0, left: -30, bottom: -10 }}>
                        <XAxis 
                            dataKey="date" 
                            tick={{ 
                                fontSize: 12, 
                                fill: colors.grey800,
                            }} 
                            axisLine={false}
                            tickLine={false}
                        />
                        <YAxis 
                            tick={{ 
                                fontSize: 12, 
                                fill: colors.grey600, 
                            }} 
                            axisLine={false}
                            tickLine={false}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar 
                            dataKey="delay" 
                            fill={colors.grey400} 
                            radius={[6, 6, 6, 6]} 
                        />
                        {movingAverageData.some(item => item.movingAverage !== undefined) && (
                            <Line 
                                type="monotone" 
                                dataKey="movingAverage" 
                                stroke={colors.key} 
                                dot={false} 
                                strokeWidth={2}
                                strokeLinecap="round" // Adds rounded ends to the line
                            />
                        )}
                    </ComposedChart>
                </ResponsiveContainer>
                <LegendContainer>
                    <KeyDot></KeyDot>
                    <TypographyStyles.BodySRegular color={colors.grey800}>Rolling average (14d)</TypographyStyles.BodySRegular>
                </LegendContainer>
            </ChartContainer>
        </ContainerCard>
    );
};

export default DelayEvolutionCard