import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { MappedJourney } from '../utils/mapJourneysData';
import { EarlierButton, LaterButton } from './Controls/EarlierLaterButtons';
import JourneyCard from './JourneyCard';


const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Center the buttons */
  align-items: center;
  width: auto; /* Ensure the container width adapts to content */
`;

export const JourneyList: React.FC<{
  journeys: MappedJourney[];
  earlierThan: string | null;
  laterThan: string | null;
  fetchJourneys: (type: 'earlier' | 'later') => void;
}> = ({ journeys, earlierThan, laterThan, fetchJourneys }) => {
  const navigate = useNavigate();

  return (
    <>
      {earlierThan && (
        <ButtonContainer>
          <EarlierButton onClick={() => fetchJourneys('earlier')} />
        </ButtonContainer>
      )}
      {journeys.length > 0 &&
        journeys.map((journey) => (
          <JourneyCard
            onClick={() => navigate(`/journey/${journey.id}`)} // navigate to the journey details screen
            key={journey.id}
            journey={journey}
          />
        ))
      }
      {laterThan && (
        <ButtonContainer>
          <LaterButton onClick={() => fetchJourneys('later')} />
        </ButtonContainer>
      )}    
    </>
  );
};