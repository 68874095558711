import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import styled from 'styled-components';
import './fonts/fonts.css'

import { MappedJourney } from './utils/mapJourneysData';
import { fetchJourneys } from './utils/fetchJourneys';

import SearchControls from './components/SearchControls';
import { JourneyList } from './components/JourneyList';
import JourneyDetailsScreen from './components/JourneyDetailsScreen';
import { Message } from './components/Message';
import TrainStatisticsScreen from './components/StatisticsPage/TrainStatisticsScreen';
import TransferStatisticsScreen from './components/StatisticsPage/TransferStatisticsScreen';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  grid-row-gap: 24px;
  padding-bottom: 32px;
`

const JourneyDetailWrapper: React.FC<{ journeys: MappedJourney[] }> = ({ journeys }) => {
  const { id } = useParams<{ id: string }>();
  const journey = journeys.find((j) => j.id === id);

  if (!journey) {
    return <Message
      type="timeout"
      message=''
    />;
  }

  return <JourneyDetailsScreen journey={journey} />;
};


const App = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(''); 
  const [origin, setOrigin] = useState<string>('');
  const [destination, setDestination] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [tripType, setTripType] = useState<'departure' | 'arrival'>('departure');
  const [options, setOptions] = useState<Record<string, string>>({});

  const [earlierThan, setEarlierThan] = useState<string | null>(null);
  const [laterThan, setLaterThan] = useState<string | null>(null);
  const [journeys, setJourneys] = useState<MappedJourney[]>([]);

  useEffect(() => {
    const storedOrigin = localStorage.getItem('originId') || '';
    const storedDestination = localStorage.getItem('destinationId') || '';
    const storedDate = `${localStorage.getItem('chosenDate') || ''}T${localStorage.getItem('chosenTime') || ''}`;
    const storedTripType = (localStorage.getItem('departOrArrive') as 'departure' | 'arrival') || 'departure';
  
    setOrigin(storedOrigin);
    setDestination(storedDestination);
    setDate(storedDate);
    setTripType(storedTripType);
  
    const options = Object.keys(localStorage).reduce((acc, key) => {
      if (key.startsWith('option_')) {
        const optionKey = key.substring('option_'.length);
        acc[optionKey] = localStorage.getItem(key) || '';
      }
      return acc;
    }, {} as Record<string, string>);
    
    setOptions(options);
  }, []);

  const handleFetchJourneys = (type: 'earlier' | 'later' | 'initial') => {
    // Clear the error state before starting a new search
    setError(false);
    
    fetchJourneys({
      type,
      origin,
      destination,
      date,
      tripType,
      options,
      earlierThan,
      laterThan,
      onJourneysFetched: (fetchedJourneys, newEarlierThan, newLaterThan) => {
        setJourneys((prevJourneys) => {
          if (type === 'earlier') {
            return [...fetchedJourneys, ...prevJourneys];
          } else if (type === 'later') {
            return [...prevJourneys, ...fetchedJourneys];
          } else {
            return fetchedJourneys;
          }
        });
        
        // Update earlierThan or laterThan based on the type of fetch
        if (type === 'earlier' && newEarlierThan !== earlierThan) {
          setEarlierThan(newEarlierThan);
        } else if (type === 'later' && newLaterThan !== laterThan) {
          setLaterThan(newLaterThan);
        } else if (type !== 'earlier' && type !== 'later') {
          // For the initial or non-paginated fetch, update both earlierThan and laterThan
          setEarlierThan(newEarlierThan);
          setLaterThan(newLaterThan);
        }
      },
      onError: (message) => {
        setError(true);
        setErrorMessage(message);
      },
      onLoadingChange: setLoading,
    });
  };



  return (
    <Router>
      <Container>
        <Routes>
        <Route
            path="/"
            element={
              <>
                <SearchControls
                  setOrigin={setOrigin}
                  setDestination={setDestination}
                  setDate={setDate}
                  setTripType={setTripType}
                  setOptions={setOptions}
                  fetchJourneys={handleFetchJourneys}
                />
                {loading && (
                  <Message
                    type="loading"
                    message=''
                  />
                )}
                {!hasError && ( 
                  <JourneyList
                    journeys={journeys}
                    earlierThan={earlierThan}
                    laterThan={laterThan}
                    fetchJourneys={handleFetchJourneys}
                  />
                )}
                {hasError && (
                  <Message
                    type="error"
                    message={`Error message: ${errorMessage}` || 'An error occurred. Please try again later.'}
                  />
                )}
              </>
            }
          />

          <Route path="/journey/:id" element={<JourneyDetailWrapper journeys={journeys} />} />

          <Route path="/train-statistics" element={<TrainStatisticsScreen />} />

          <Route path="/transfer-statistics" element={<TransferStatisticsScreen />} />

        </Routes>
      </Container>
    </Router>
  );
};

export default App;
