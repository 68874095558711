// src/utils/fetchJourneys.ts

import { MappedJourney, mapJourneysData, Journey } from './mapJourneysData';

type FetchJourneysParams = {
    type?: 'earlier' | 'later' | 'initial';
    origin: string;
    destination: string;
    date: string;
    tripType: 'departure' | 'arrival';
    options: Record<string, string>;
    earlierThan: string | null;
    laterThan: string | null;
    onJourneysFetched: (journeys: MappedJourney[], newEarlierThan: string | null, newLaterThan: string | null) => void;
    onError: (message: string) => void;
    onLoadingChange: (isLoading: boolean) => void;
};

export const fetchJourneys = ({
    type = 'initial',
    origin,
    destination,
    date,
    tripType,
    options,
    earlierThan,
    laterThan,
    onJourneysFetched,
    onError,
    onLoadingChange,
  }: FetchJourneysParams) => {
    // Start loading
    onLoadingChange(true);

    // Determine the appropriate time parameters
    const timeParam =
    type === 'earlier' ? `earlierThan=${encodeURIComponent(earlierThan || '')}`
    : type === 'later' ? `laterThan=${encodeURIComponent(laterThan || '')}`
    : `${tripType}=${encodeURIComponent(date)}`;

  // Create query parameters
  const queryParams = [
    `from=${encodeURIComponent(origin)}`,
    `to=${encodeURIComponent(destination)}`,
    ...Object.entries(options).map(([key, value]) => `${key}=${encodeURIComponent(value)}`),
    timeParam,
  ]
    .filter(Boolean)
    .join('&');

    const requestUrl = `https://api.chuuchuu.com/journeys?${queryParams}`;
    console.log(requestUrl);

    fetch(requestUrl)
      .then((response) => response.json())
      .then((data) => {
        const { earlierThan: newEarlierThan, laterThan: newLaterThan, journeys: newJourneys } = data;
        const mappedJourneys = mapJourneysData(newJourneys as Journey[]);

      // Notify the parent component of the fetched data
      onJourneysFetched(mappedJourneys, newEarlierThan, newLaterThan);
    })
    .catch((error) => {
        onError(error.message || `We're not sure what just happened there...`);
      })
      .finally(() => {
        onLoadingChange(false);
      });
}
