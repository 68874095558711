// KPIsContainer.tsx
import React from "react";
import styled from "styled-components";
import { Journey } from "../../utils/mapJourneysData";
import KPIBox from "./KPI";
import { getColor } from "../../utils/colorFormatter";
import train from '../../icons/train.svg';
import hourglass from '../../icons/hourglass.svg';
import cancel from '../../icons/cancel.svg';
import brokenlink from '../../icons/brokenlink.svg';

const Container = styled('div')`
display: flex;
align-items: flex-start;
align-content: flex-start;
gap: 16px;
align-self: stretch;
flex-wrap: wrap;
margin-top: 32px;
`;

type KPIsContainerProps = {
  journey: Journey;
};

const KPIsContainer: React.FC<KPIsContainerProps> = ({ journey }) => {
  // Extract, round, and default KPIs to null if unavailable
  const reliability = journey.reliability?.reliabilityScore != null
    ? Math.round(journey.reliability.reliabilityScore)
    : null;

  const averageDelay = journey.journeyDelayStats?.averageDelayAtDest != null
    ? Math.round(journey.journeyDelayStats.averageDelayAtDest)
    : null;

  const cancellationRate = journey.journeyDelayStats?.journeyCancellationRate != null
    ? Math.round(journey.journeyDelayStats.journeyCancellationRate)
    : null;

    const missedTransfers = journey.transferStats?.length
    ? (() => {
        // Extract and filter valid percentageMissedConnections values
        const validConnections = journey.transferStats
          .map((stats) => stats.percentageMissedConnections)
          .filter((value) => value != null); // Remove null and undefined values
  
        // Return the rounded maximum if there are valid values, else null
        return validConnections.length > 0
          ? Math.round(Math.max(...validConnections))
          : null;
      })()
    : null;

  // Define color conditions using the existing colorFormatter
  const reliabilityColor = (value: number) => getColor(value, 'chuuchuuScore');
  const delayColor = (value: number) => getColor(value, 'delayInMinutes');
  const cancellationColor = (value: number) => getColor(value, 'cancellationPercentage');
  const transferColor = (value: number) => getColor(value, 'missedTransfersPercentage');

  return (
    <Container>
      {reliability !== null && (
        <KPIBox
          icon={train}
          title="chuuchuu score™"
          value={`${reliability}`}
          colorCondition={reliabilityColor}
          isReliability={true} // Set this flag for special styling
        />
      )}
      {averageDelay !== null && (
        <KPIBox
          icon={hourglass}
          title="average delay"
          value={`${averageDelay >= 0 ? `+${averageDelay}` : `${averageDelay}`}m`}
          colorCondition={delayColor}
        />
      )}
      {cancellationRate !== null && (
        <KPIBox
          icon={cancel}
          title="cancellation rate"
          value={`${cancellationRate}%`}
          colorCondition={cancellationColor}
        />
      )}
      {missedTransfers !== null && (
        <KPIBox
          icon={brokenlink}
          title="missed transfers"
          value={`${missedTransfers}%`}
          colorCondition={transferColor}
        />
      )}
    </Container>
  );
};

export default KPIsContainer;