// KPI.tsx
import React from "react";
import styled from "styled-components";
import * as TypographyStyles from '../TypographyStyles';
import { colors } from "../../utils/colors";
import { ReactSVG } from 'react-svg';


// Define styled components for the box and content
const Box = styled("div")<{ bgColor: string; borderColor: string }>`
  display: flex;
  width: 118px;
  height: 118px;
  padding: 10px 9px 10px 9px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 12px;
  flex-shrink: 0;
  border-bottom: 2px solid ${(props) => props.borderColor};
  background: ${(props) => props.bgColor};
  box-sizing: border-box; /* Include padding and borders in the width and height */
`;

const IconWrapper = styled("div")<{ iconColor: string }>`
  font-size: 24px;
  color: ${(props) => props.iconColor};  // Use color for SVG fill
`;

const Content = styled("div")<{ titleColor: string }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  color: ${(props) => props.titleColor};
`;

const Value = styled("div")<{ valueColor: string }>`
  color: ${(props) => props.valueColor};
`;

// Define props for the KPIBox
type KPIBoxProps = {
  icon: string; // Path to SVG file
  title: string;
  value: string | number;
  colorCondition: (value: number) => string;
  isReliability?: boolean;
};

const KPIBox: React.FC<KPIBoxProps> = ({
    icon,
    title,
    value,
    colorCondition,
    isReliability = false,
  }) => {  // Convert the value to a number if possible for color condition checks

  const numericValue = typeof value === 'number' ? value : parseFloat(value.toString());

  // Determine the base color based on the value
  const baseColor = isNaN(numericValue) ? colors.grey600 : colorCondition(numericValue);

  // Predefined color mappings
  const lightVariant = {
    [colors.red]: colors.red300,
    [colors.orange]: colors.orange300,
    [colors.green]: colors.green300,
    [colors.grey600]: colors.grey300, // Default for unknown color
  };

  const darkVariant = {
    [colors.red]: colors.red600,
    [colors.orange]: colors.orange600,
    [colors.green]: colors.green600,
    [colors.grey600]: colors.grey800, // Default for unknown color
  };

  // Determine colors based on whether the KPI is reliability or not
  const bgColor = isReliability ? baseColor : lightVariant[baseColor] || colors.grey300;
  const iconColor = isReliability ? colors.white : baseColor;
  const titleColor = isReliability ? colors.white : darkVariant[baseColor];
  const valueColor = isReliability ? colors.white : darkVariant[baseColor] || colors.grey800;
  const borderColor = isReliability ? darkVariant[baseColor] : baseColor || colors.grey800;

  return (
    <Box bgColor={bgColor} borderColor={borderColor}>
      <IconWrapper iconColor={iconColor}>
        <ReactSVG
          src={icon} // Path to your SVG file
          beforeInjection={(svg) => {
            svg.setAttribute('fill', iconColor); // Set the fill color dynamically
          }}
        />
      </IconWrapper>
      <Content titleColor={titleColor}>
        <TypographyStyles.KPITitle>
          {title}
        </TypographyStyles.KPITitle>
        <Value valueColor={valueColor}>
          <TypographyStyles.KPIValueLarge>
            {value}
          </TypographyStyles.KPIValueLarge>
        </Value>
      </Content>
    </Box>
  );
};

export default KPIBox;