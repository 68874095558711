import bike from '../../icons/bike.svg'
import bikereservation from '../../icons/bikereservation.svg'
import plug from '../../icons/plug.svg'
import aircon from '../../icons/aircon.svg'
import wifi from '../../icons/wifi.svg'
import seatreservation from '../../icons/seatreservation.svg'
import comfortcheckin from '../../icons/comfortcheckin.svg'
import restaurant from '../../icons/restaurant.svg'
import ramp from '../../icons/ramp.svg'
import wheelchair from '../../icons/wheelchair.svg'
import ticketmachine from '../../icons/ticketmachine.svg'
import bar from '../../icons/bar.svg'
import nursing from '../../icons/nursing.svg'

type Props = {
  code?: string
}

const icons: { [x: string]: string } = {
  'bicycle-conveyance': bike,
  'bicycle-conveyance-reservation': bikereservation,
  'power-sockets': plug,
  'air-conditioned': aircon,
  'wifi': wifi,
  'compulsory-reservation': seatreservation,
  'komfort-checkin': comfortcheckin,
  'on-board-restaurant': restaurant,
  'boarding-ramp': ramp,
  'ER': ramp,
  'EA': wheelchair,
  'FM': ticketmachine,
  'OR': seatreservation,
	'BW': bar,
  'SA': nursing,
  'FG': seatreservation,
  '97': wheelchair
}

const AmenityIcon = ({ code }: Props) => {
  if (!code) return null;
  const icon = icons[code];

  if (!icon) return null;

  return <img alt={code} style={{ height: '14px' }} src={icon} />
}

export default AmenityIcon;