import * as TypographyStyles from './TypographyStyles';
import styled from "styled-components";
import levelHigh from '../icons/levelHigh.svg'
import levelMedium from '../icons/levelMedium.svg'
import levelLow from '../icons/levelLow.svg'
import { colors } from "../utils/colors";

type LineBadgeProps = {
  lineName: string;
  occupancy: string;
}
const LineBadge = ({ lineName, occupancy}: LineBadgeProps) => {
  const Container = styled('div')`
    display: flex;
    padding: 4px 8px;
    border: 1px solid ${colors.grey600};
    border-radius: 6px;
    grid-column-gap: 6px;
    width: max-content;
  `

  const line = lineName ? lineName.split(' ') : '';
  const getOccupancyIcon = () => {
    if (occupancy === 'low') return levelLow;
    else if (occupancy === 'low-to-medium') return levelMedium;
    else if (occupancy === 'high' || occupancy === 'very-high' || occupancy === 'exceptionally-high') return levelHigh;
    return '';
  }

  return (
    <Container>
      <div>
        <TypographyStyles.BodyLBold color={colors.grey800}>{line[0]}</TypographyStyles.BodyLBold>
        {' '}
        <TypographyStyles.BodyLRegular color={colors.grey600}>{line[1]}</TypographyStyles.BodyLRegular>
      </div>
      {occupancy && <img alt="occupancy" src={getOccupancyIcon()} />}
    </Container>
  );
}

export default LineBadge;