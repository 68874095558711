// DateTimeCard.tsx
import { useState, useEffect } from 'react';
import { parseISO } from 'date-fns'; 
import styled from 'styled-components';
import * as TypographyStyles from '../TypographyStyles';
import { colors } from '../../utils/colors'
import { format, addDays, set } from 'date-fns';
import { CalendarProps } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import StyledCalendar from '../Calendar';
import * as Buttons from '../Controls/Buttons';
import * as Dropdowns from '../Controls/Dropdowns';
import { ReactComponent as LeftArrow } from '../../icons/chevron-left.svg';
import { ReactComponent as RightArrow } from '../../icons/chevron-right.svg';

// Styled components
const FrameContainer = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.10);  
  width: 80%;
  max-width: 600px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  box-sizing: border-box; /* Ensure padding and borders are included in width calculation */
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const ControlSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 28px;
  align-self: stretch;
`;

const TimeSection = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AtText = styled(TypographyStyles.BodyXLSemibold)`
  color: ${colors.grey800};
`;

// Get today's date
const today = new Date();
today.setHours(0, 0, 0, 0);

// Define types for react-calendar
interface DateTimeCardProps {
  onClose: () => void;
  onApply: (dateTime: string, type: 'departure' | 'arrival') => void;
  initialDate: string;
  initialTime: string;
  initialType: 'departure' | 'arrival';
}

const DateTimeCard = ({ onClose, onApply, initialDate, initialTime, initialType }: DateTimeCardProps) => {
  // Initialize selectedDate using initialDate if it exists, otherwise use the default
  const [selectedDate, setSelectedDate] = useState<Date>(() => {
    // Check if initialDate is provided and parse it
    if (initialDate) {
      return parseISO(initialDate);
    }
    // Default to one day ahead if initialDate is not provided
    return addDays(new Date(), 1);
  });  

  // Initialize selectedTime using initialTime if it exists, otherwise use an empty string
  const [selectedTime, setSelectedTime] = useState<string>(
    initialTime || ''
  );

  // Initialize departOrArrive using initialType
  const [departOrArrive, setDepartOrArrive] = useState<'departure' | 'arrival'>(initialType);

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  useEffect(() => {
    if (initialTime) {
      setSelectedTime(initialTime);
    } else {
      setSelectedTime(getClosestTimeSlot(new Date()));
    }
  }, [initialTime]);

  const getClosestTimeSlot = (date: Date) => {
    const minutes = date.getMinutes();
    const closestHalfHour = minutes < 30 ? 0 : 30;
    return format(set(date, { minutes: closestHalfHour, seconds: 0, milliseconds: 0 }), 'HH:mm');
  };

  const generateTimeOptions = () => {
    const times = [];
    for (let i = 0; i < 24; i++) {
      times.push(`${i.toString().padStart(2, '0')}:00`);
      times.push(`${i.toString().padStart(2, '0')}:30`);
    }
    return times;
  };

  const handleNowClick = () => {
    const now = new Date();
    setSelectedDate(now);
    setSelectedTime(getClosestTimeSlot(now));
    setHasChanges(true);
  };

  // Updated handleDateChange to handle single Date values
  const handleDateChange: CalendarProps['onChange'] = (value, event) => {
    if (value instanceof Date) {
      setSelectedDate(value);
      setHasChanges(true);
    }
  };

  const handleApply = () => {
    const date = format(selectedDate, 'yyyy-MM-dd');
    const dateTime = `${date}T${selectedTime}`;
    onApply(dateTime, departOrArrive);
    setHasChanges(false);
  };

  return (
    <FrameContainer>
      <HeaderSection>
        <Buttons.CloseButton onClick={onClose} disabled={false} />
        <Buttons.TextButtonM onClick={handleNowClick}>Now</Buttons.TextButtonM>
      </HeaderSection>

      <ControlSection>
        <StyledCalendar
          onChange={handleDateChange}
          value={selectedDate}
          locale="en-UK"
          selectRange={false}
          minDate={today}
          minDetail={"month"}
          tileClassName={({ date, view }: any) => {
            if (view === 'month') {
              const currentDate = new Date(date);
              currentDate.setHours(0, 0, 0, 0); // Reset time for comparison
              return date < today ? 'react-calendar__tile--disabled' : '';
            }
            return '';
          }}
          prevLabel={<LeftArrow />} // Use custom icon for previous button
          nextLabel={<RightArrow />} // Use custom icon for next button
        />

        <TimeSection>
          <Buttons.SegmentedControlS
            activeValue={departOrArrive}
            options={[
              { label: 'Departure', value: 'departure' },
              { label: 'Arrival', value: 'arrival' },
            ]}
            onChange={(value) => {
              setDepartOrArrive(value as 'departure' | 'arrival');
              setHasChanges(true);
            }}
          />

          <DropdownContainer>
            <AtText>at</AtText>

            <Dropdowns.DropdownS
              value={selectedTime}
              onChange={(e) => {
                setSelectedTime(e.target.value);
                setHasChanges(true);
              }}
            >
              {generateTimeOptions().map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </Dropdowns.DropdownS>
          </DropdownContainer>
        </TimeSection>
      </ControlSection>

      <Buttons.CTAButtonFilled onClick={handleApply} disabled={!hasChanges}>
        Apply
      </Buttons.CTAButtonFilled>
    </FrameContainer>
  );
};

export default DateTimeCard;