export const colors = {
    white: '#FFF',
    red: '#F00',
    red300: '#FFE3E3',
    red600: '#DC0000',
    orange: '#FF8A00',
    orange300: '#FFE9D0',
    orange600: '#D47300',
    green: '#19B300',
    green300: '#DFFFD9',
    green600: '#138700',
    black: '#0A0A0A',
    grey200: '#F5F5F5',
    grey300: '#E3E3E3',
    grey400: '#c5c5c5',
    grey600: '#939393',
    grey800: '#2e2e2e',
    lightGrey: '#F6F6F6',
    keyBackground: '#F5EEFF',
    keyHover: '#E1CAFF',
    key: '#9747FF',
    keyDarker: '#7E3BD6',
    keyDarkest: '#662FAE',
    blue: '#D8EAFF',
    infoBlue: '#001FC1'
}