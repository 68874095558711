import React from 'react';
import styled from 'styled-components';
import { LineStats } from './Types/lineStats';
import TimeContainer from '../TimeContainer'
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';


const ContainerCard = styled('div')`
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    border-bottom: 2px solid ${colors.grey400};
    background: ${colors.white};
`

const CardHeader = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`

const AvgDelayTable = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    align-self: stretch;
`

const StationItem = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
`

const DataRow = styled('div')`
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
`

const TimeIndication = styled('div')`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 200px;
`

const ColourScale = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
`

const ColorBar = styled.div<{ maxDelay: number }>`
  position: relative;
  width: 100%;
  height: 6px;
  background: ${props => {
    const { maxDelay } = props;
    
    // Calculate positions based on maxDelay
    const color1Position = 0; // Start of the gradient
    const color2Position = Math.min(10 / maxDelay * 100, 100); // 15% of maxDelay
    const color3Position = Math.min(20 / maxDelay * 100, 100); // 30% of maxDelay
    const color4Position = Math.min(60 / maxDelay * 100, 100); // 60% of maxDelay
    
    return `linear-gradient(to right, 
      #52FF00 ${color1Position}%, 
      #FCF200 ${color2Position}%, 
      #F00 ${color3Position}%, 
      #AF0000 ${color4Position}%)`;
  }};
  border-radius: 4px;
`;

const Dot = styled.div<{ position: number }>`
  position: absolute;
  top: 50%;
  left: ${props => props.position}%;
  width: 6px;
  height: 6px;
  background-color: ${colors.black};
  border: 2px solid ${colors.white}; /* Combine border-width and border-color */
  border-radius: 50%;
  transform: translate(-50%, -50%);
`;

const Divider = styled.div`
  height: 0.5px;
  background-color: ${colors.grey300}; // Default color if none is provided
  width: 100%;
  margin: 0;
`;


// Define an interface representing the expected structure of the data prop
interface AvgDelayCardProps {
    data: LineStats[]; // Expecting an array of LineStats objects
}

const AvgDelayCard: React.FC<AvgDelayCardProps> = ({ data }) => {
    // Find min and max delays across all stops
    const minDelay = Math.min(
        ...data.flatMap(stop => [stop.avgArrivalDelay, stop.avgDepartureDelay])
    );
    const maxDelay = Math.max(
        ...data.flatMap(stop => [stop.avgArrivalDelay, stop.avgDepartureDelay])
    );

    return (
        <ContainerCard>
            <CardHeader>
                <TypographyStyles.BodyLSemibold color={colors.black}>Average delay</TypographyStyles.BodyLSemibold>
                <TypographyStyles.BodyLRegular color={colors.grey600}>(last 30 days)</TypographyStyles.BodyLRegular>
            </CardHeader>
            <AvgDelayTable>
                {data.map((stop, index) => (
                <React.Fragment key={index}>
                    <StationItem>
                        <TypographyStyles.BodyMSemibold color={colors.grey800}>{stop.stopName}</TypographyStyles.BodyMSemibold>

                        {/* Conditionally render arrival data row if available */}
                        {stop.plannedArrival.length > 0 && (
                            <DataRow>
                                <TimeIndication>
                                <TypographyStyles.BodyMRegular 
                                    color={colors.grey600} 
                                    style={{ width: '64px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    Arrival
                                </TypographyStyles.BodyMRegular>
                                   <TimeContainer 
                                        time={stop.plannedArrival[0]} // Access the first element
                                        averageDelay={stop.avgArrivalDelay}
                                        weight={400}
                                    />
                                </TimeIndication>
                                <ColourScale>
                                    <ColorBar maxDelay={maxDelay}>
                                        <Dot 
                                            position={((stop.avgArrivalDelay - minDelay) / (maxDelay - minDelay)) * 100}
                                        />
                                    </ColorBar>
                                </ColourScale>
                            </DataRow>
                        )}

                        {/* Conditionally render departure data row if available */}
                        {stop.plannedDeparture.length > 0 && (
                            <DataRow>
                                <TimeIndication>
                                    <TypographyStyles.BodyMRegular                                    
                                        color={colors.grey600} 
                                        style={{ width: '64px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        Departure
                                    </TypographyStyles.BodyMRegular>
                                    <TimeContainer 
                                        time={stop.plannedDeparture[0]} 
                                        averageDelay={stop.avgDepartureDelay}  
                                        weight={400}
                                    />
                                </TimeIndication>
                                <ColourScale>
                                    <ColorBar maxDelay={maxDelay}>
                                        <Dot 
                                            position={((stop.avgDepartureDelay - minDelay) / (maxDelay - minDelay)) * 100}
                                        />
                                    </ColorBar>
                                </ColourScale>
                            </DataRow>
                        )}
                    </StationItem>
                    {index < data.length - 1 && <Divider />} {/* Add divider between items */}
                </React.Fragment>
                ))}
            </AvgDelayTable>
        </ContainerCard>
    );
};

export default AvgDelayCard;