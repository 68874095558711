import * as TypographyStyles from '../TypographyStyles';
import styled from "styled-components";
import { colors } from "../../utils/colors";
import LineBadge from "../LineBadge";

const Container = styled('div')`
  display: flex;
  align-items: center;
  align-content: center;
  align-self: stretch;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px 0 6px 0;
`

type LinesInfoProps = {
  legs: any[];
}

const LinesInfo = ({ legs }: LinesInfoProps) => {

  const viaStops = legs.map((leg: any, index: number) => {
    if (index === 0) return undefined;
    if (!leg.line) return undefined;
    return leg.origin.name
  }).filter(Boolean).join(', ')
  // Filter out journey legs that don's have a line, e.g. walking transfers
  const trainLines = legs.filter(leg => !!leg.line)
  return (
    <>
      <Container>
        {trainLines.map(leg => (
          <LineBadge key={leg.line?.name} lineName={leg.line?.name} occupancy={leg.loadFactor} />
        ))}

      </Container>
      {viaStops.length > 0 && (
        <TypographyStyles.BodySRegular color={colors.grey800}>via {viaStops}</TypographyStyles.BodySRegular>
      )}
    </>
  )
}

export default LinesInfo;