import { v4 as uuidv4 } from 'uuid';
import { getJourneyDurationInMinutes } from "./durationUtils";

export type Leg = any;

type TransferStat = {
  averageTransferTime: number;
  percentageMissedConnections: number
}

export type Journey = {
  price: Price;
  reliability: {
    reliabilityScore: number;
  }
  legs: Leg[];
  transferStats: TransferStat[]
  journeyDelayStats: {
    averageDelayAtDest?: number | null;
    journeyCancellationRate?: number | null;
  }
}

type Tag = 'arrives-first' | 'cheapest' | 'fastest' | 'most-reliable' | 'direct' | 'least-transfers'
// type Line = {
//   name: string;
//   loadFactor: 'low' | 'low-to-medium' | 'high' | 'very-high'
// }
type Price = {
  amount: number;
  currency: string;
  hint?: any;
}
// type Transfer = {
//   realTransferTimeInMinutes: number;
//   missedTransfersRate: number;
// }

export type MappedJourney = Journey & {
  // tags: Tag[];
  // plannedDepartureTime: string;
  // departureDelayInMinutes?: number;
  // plannedArrivalTime: string
  // arrivalDelayInMinutes?: number;
  // plannedDurationInMinutes: number;
  // viaStopNames: string[];
  // lines: Line[];
  // reliabilityScore: number;
  // price: Price;
  // transfers: Transfer[]
  tagsData: DataForTags;
  tags?: string[];
  id: string;
}

type DataForTags = {
  price: number;
  arrivalTime: string;
  duration: number;
  reliabilityScore: number;
  direct: boolean;
  numberOfTransfers: number;
}

const generateTagsData = (journey: Journey): DataForTags => {
  const getArrivalTime = (journey: Journey) => {
    const lastLeg = journey.legs[journey.legs.length - 1];
    if (!!lastLeg.arrival) return lastLeg.arrival;
    return lastLeg.plannedArrival
  } 

  return {
    price: journey.price.amount,
    reliabilityScore: journey.reliability.reliabilityScore,
    arrivalTime: getArrivalTime(journey),
    duration: getJourneyDurationInMinutes(journey.legs),
    direct: journey.legs.length === 1,
    numberOfTransfers: journey.legs.length - 1
  }
}

const generateTags = (
  tagsData: DataForTags, 
  cheapest?: number, 
  mostReliable?: number, 
  fastest?: number, 
  arrivesFirst?: string,
  leastTransfers?: number,
  isFirstJourney?: boolean
): Tag[] => {
  const tags = [
    tagsData.direct ? 'direct' : undefined,
    cheapest === tagsData.price ? 'cheapest' : undefined,
    mostReliable === tagsData.reliabilityScore ? 'most-reliable' : undefined,
    fastest === tagsData.duration ? 'fastest' : undefined,
    !isFirstJourney && arrivesFirst === tagsData.arrivalTime ? 'arrives-first' : undefined,
    leastTransfers === tagsData.numberOfTransfers ? 'least-transfers' : undefined
  ].filter(el => el)

  return tags as Tag[];
}

const mapLeg = (leg: Leg) => {
  return {
    ...leg,
    arrivalDelay: leg.arrivalDelay ? leg.arrivalDelay / 60 : leg.arrivalDelay,
    departureDelay: leg.departureDelay ? leg.departureDelay / 60 : leg.departureDelay
  }
}


export const mapJourneysData = (journeys: Journey[]): MappedJourney[] => {
  const mappedJourneys = journeys.map(journey => ({
    ...journey,
    id: uuidv4(),
    tagsData: generateTagsData(journey)
  }))

  let cheapest = Number.MAX_VALUE
  let mostReliable = 0
  let fastest = Number.MAX_VALUE
  let arrivesFirst = mappedJourneys[0].tagsData.arrivalTime
  let leastTransfers = Number.MAX_VALUE
  const hasDirect = mappedJourneys.some(journey => journey.tagsData.direct === true)

  for (let journey of mappedJourneys) {
    if (journey.tagsData.price > 0 && journey.tagsData.price < cheapest) {
      cheapest = journey.tagsData.price
    }
    if (journey.tagsData.reliabilityScore > 0 && journey.tagsData.reliabilityScore > mostReliable) {
      mostReliable = journey.tagsData.reliabilityScore
    }
    if (journey.tagsData.duration < fastest) {
      fastest = journey.tagsData.duration
    }
    if (new Date(arrivesFirst) > new Date(journey.tagsData.arrivalTime)) {
      arrivesFirst = journey.tagsData.arrivalTime
    }

    if (hasDirect === false && journey.tagsData.numberOfTransfers < leastTransfers) {
      leastTransfers = journey.tagsData.numberOfTransfers
    }
  }

  const showLeastTransfers = mappedJourneys.filter(j => j.tagsData.numberOfTransfers === leastTransfers).length !== mappedJourneys.length

  const journeysWithTags = mappedJourneys.map((journey, index) => ({
    ...journey,
    legs: journey.legs.map(l => mapLeg(l)),
    tags: generateTags(      
      journey.tagsData, 
      cheapest, 
      mostReliable, 
      fastest, 
      arrivesFirst, 
      showLeastTransfers ? leastTransfers : undefined, 
      index === 0 // Pass true if this is the first journey)
    )
  }))

  return journeysWithTags
}