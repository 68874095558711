// OptionsCard.tsx
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as TypographyStyles from '../TypographyStyles';
import { colors } from '../../utils/colors'
import * as Buttons from '../Controls/Buttons';
import * as Dropdowns from '../Controls/Dropdowns';
import Switch from '../Controls/Switches';


const FrameContainer = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.10);  
  width: 80%;
  max-width: 600px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  box-sizing: border-box; /* Ensure padding and borders are included in width calculation */
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

const SettingsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const SectionTitle = styled(TypographyStyles.BodySRegular)`
  color: ${colors.grey600};
  leading-trim: both;
  text-edge: cap;
`;

const SettingItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

const SettingLabel = styled(TypographyStyles.BodyLRegular)`
  flex: 1 0 0;  
  color: ${colors.grey800};
  leading-trim: both;
  text-edge: cap;
`;

interface OptionsCardProps {
  onClose: () => void;
  onApply: (options: Record<string, string>) => void;
  initialOptions: Record<string, string>;
}

const defaultValues = {
  transfers: '-1',
  transferTime: '0',
  accessibility: 'none',
  bike: 'false',
  longdistance: 'true',
  regional: 'true',
  suburban: 'true',
  local: 'true',
};

const OptionsCard = ({ onClose, onApply, initialOptions }: OptionsCardProps) => {
  const [numberOfTransfers, setNumberOfTransfers] = useState(initialOptions.transfers || defaultValues.transfers);
  const [minimumTransferTime, setMinimumTransferTime] = useState(initialOptions.transferTime || defaultValues.transferTime);
  const [accessibleJourneys, setAccessibleJourneys] = useState(initialOptions.accessibility || defaultValues.accessibility);
  const [bikeFriendly, setBikeFriendly] = useState(initialOptions.bike === 'true' || defaultValues.bike === 'true');
  
  const [longDistanceTrains, setLongDistanceTrains] = useState<boolean>(initialOptions.longdistance === 'true');
  const [regionalTrains, setRegionalTrains] = useState<boolean>(initialOptions.regional === 'true');
  const [suburbanTrains, setSuburbanTrains] = useState<boolean>(initialOptions.suburban === 'true');
  const [localPublicTransport, setLocalPublicTransport] = useState<boolean>(initialOptions.local === 'true');

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  // Compare current state with initialOptions to check for changes
  useEffect(() => {
    // Create an object with the current state values
    const currentOptions = {
      transfers: numberOfTransfers,
      transferTime: minimumTransferTime,
      accessibility: accessibleJourneys,
      bike: bikeFriendly ? 'true' : 'false',
      longdistance: longDistanceTrains ? 'true' : 'false',
      regional: regionalTrains ? 'true' : 'false',
      suburban: suburbanTrains ? 'true' : 'false',
      local: localPublicTransport ? 'true' : 'false',
    };
  
    // Compare the current options with the initial options
    const isChanged = JSON.stringify(currentOptions) !== JSON.stringify(initialOptions);
  
    // Update hasChanges based on whether there are changes
    setHasChanges(isChanged);
  }, [
    numberOfTransfers,
    minimumTransferTime,
    accessibleJourneys,
    bikeFriendly,
    longDistanceTrains,
    regionalTrains,
    suburbanTrains,
    localPublicTransport,
    initialOptions
  ]);

  // Function to reset all state variables to their initial values
  const handleReset = () => {
    setNumberOfTransfers(defaultValues.transfers);
    setMinimumTransferTime(defaultValues.transferTime);
    setAccessibleJourneys(defaultValues.accessibility);
    setBikeFriendly(defaultValues.bike === 'true');
    setLongDistanceTrains(defaultValues.longdistance === 'true');
    setRegionalTrains(defaultValues.regional === 'true');
    setSuburbanTrains(defaultValues.suburban === 'true');
    setLocalPublicTransport(defaultValues.local === 'true');
  };

  const handleApply = () => {
    const options = {
      transfers: numberOfTransfers,
      transferTime: minimumTransferTime,
      accessibility: accessibleJourneys,
      bike: bikeFriendly ? 'true' : 'false',
      longdistance: longDistanceTrains ? 'true' : 'false',
      regional: regionalTrains ? 'true' : 'false',
      suburban: suburbanTrains ? 'true' : 'false',
      local: localPublicTransport ? 'true' : 'false',
    };
    onApply(options);
    setHasChanges(false)
    onClose();
  };

  return (
    <FrameContainer>
      <HeaderSection>
        <Buttons.CloseButton onClick={onClose} disabled={false} />
        <Buttons.TextButtonM onClick={handleReset}>Reset</Buttons.TextButtonM>
      </HeaderSection>
        <OptionsList>
          <OptionsSection>
            <SectionTitle>Journey Options</SectionTitle>
            <SettingsList>
              <SettingItem>
                <SettingLabel>Number of transfers</SettingLabel>
                <Dropdowns.DropdownS
                  id="number-of-transfers"
                  value={numberOfTransfers}
                  onChange={(e) => setNumberOfTransfers(e.target.value)}
                >
                  <option value="-1">Any</option>
                  <option value="1">Maximum 1</option>
                  <option value="2">Maximum 2</option>
                  <option value="3">Maximum 3</option>
                  <option value="0">Direct only</option>
                </Dropdowns.DropdownS>
              </SettingItem>

              <SettingItem>
                <SettingLabel>Minimum transfer time</SettingLabel>
                <Dropdowns.DropdownS
                  id="minimum-transfer-time"
                  value={minimumTransferTime}
                  onChange={(e) => setMinimumTransferTime(e.target.value)}
                >
                  <option value="0">None</option>
                  <option value="5">5 min</option>
                  <option value="15">15 min</option>
                  <option value="30">30 min</option>
                  <option value="60">1 hour</option>
                </Dropdowns.DropdownS>
              </SettingItem>

              <SettingItem>
                <SettingLabel>Accessible journeys</SettingLabel>
                <Dropdowns.DropdownS
                  id="accessible-journeys"
                  value={accessibleJourneys}
                  onChange={(e) => setAccessibleJourneys(e.target.value)}
                >
                  <option value="none">No</option>
                  <option value="partial">Partial</option>
                  <option value="complete">Yes</option>
                </Dropdowns.DropdownS>
              </SettingItem>

              <SettingItem>
                <SettingLabel>Bike-friendly journeys</SettingLabel>
                <Switch
                  id="bike-friendly"
                  toggled={bikeFriendly}
                  onChange={(e) => setBikeFriendly(e.target.checked)}
                />
              </SettingItem>
            </SettingsList>
          </OptionsSection>

          <OptionsSection>
            <SectionTitle>Transport Modes</SectionTitle>
            <SettingsList>
              <SettingItem>
                <SettingLabel>Long distance trains</SettingLabel>
                <Switch
                  id="long-distance"
                  toggled={longDistanceTrains}
                  onChange={(e) => setLongDistanceTrains(e.target.checked)}
                />
              </SettingItem>

              <SettingItem>
                <SettingLabel>Regional trains</SettingLabel>
                <Switch
                  id="regional"
                  toggled={regionalTrains}
                  onChange={(e) => setRegionalTrains(e.target.checked)}
                />
              </SettingItem>

              <SettingItem>
                <SettingLabel>Suburban trains</SettingLabel>
                <Switch
                  id="suburban"
                  toggled={suburbanTrains}
                  onChange={(e) => setSuburbanTrains(e.target.checked)}
                />
              </SettingItem>

              <SettingItem>
                <SettingLabel>Local public transportation</SettingLabel>
                <Switch
                  id="local"
                  toggled={localPublicTransport}
                  onChange={(e) => setLocalPublicTransport(e.target.checked)}
                />
              </SettingItem>
            </SettingsList>
          </OptionsSection>
        </OptionsList>
      <Buttons.CTAButtonFilled onClick={handleApply} disabled={!hasChanges}>
        Apply
      </Buttons.CTAButtonFilled>
    </FrameContainer>
  );
};

export default OptionsCard;