import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';
import { TransferHistory } from './Types/transferHistory';
import { formatDuration } from '../../utils/durationUtils';
import { getColor } from '../../utils/colorFormatter';
import { NextButton, PrevButton } from '../Controls/PrevNextButtons';


const ContainerCard = styled('div')`
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    border-bottom: 2px solid ${colors.grey400};
    background: ${colors.white};
`

const CardHeader = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`

const TableContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`

const TableHeader = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
`

const HeaderItem = styled(TypographyStyles.BodySRegular)`
    display: flex;
    min-width: 80px;
    align-items: flex-start;
    gap: 10px;
    color: ${colors.grey600}
`

const TransferHistoryTable = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
`

const DataRow = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`

const CellItem = styled(TypographyStyles.BodyMRegular)`
    display: flex;
    min-width: 80px;
    align-items: flex-start;
    gap: 10px;
`

const Divider = styled.div`
  height: 0.5px;
  background-color: ${colors.grey300}; // Default color if none is provided
  width: 100%;
  margin: 0;
`;

const CardFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    align-self: stretch;
`;

const PaginatorContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
`;



interface TransferHistoryCardProps {
    transferHistoryData: TransferHistory[];
}

const formatDate = (dateString: string | null | undefined) => {
    if (!dateString) return '';
  
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
  
    const isToday = date.toDateString() === today.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();
  
    if (isToday) {
      return 'Today';
    } else if (isYesterday) {
      return 'Yesterday';
    } else {
      return date.toLocaleDateString('nl-BE', { month: '2-digit', day: '2-digit' });
    }
};

const getDisplayInfo = (
    transferTime: number | null | undefined,
    arrivalCancelled: boolean | null,
    departureCancelled: boolean | null
) => {
    if (arrivalCancelled || departureCancelled) {
        return { formattedTransferTime: 'Not feasible', displayColor: colors.red };
    }
    if (transferTime === null || transferTime === undefined) {
        return { formattedTransferTime: '-', displayColor: colors.grey600 };
    }
    if (transferTime < 1) {
        return { formattedTransferTime: 'Missed', displayColor: colors.red };
    }
    return { formattedTransferTime: null, displayColor: null };
};

const PAGE_SIZE = 10;

const TransferHistoryCard: React.FC<TransferHistoryCardProps> = ({ transferHistoryData }) => {
    const [currentPage, setCurrentPage] = useState(1);
    
    // Determine which date to use based on selectedOption
    const getDate = (entry: TransferHistory) => entry.plannedArrival;
    const getTransferTime = (entry: TransferHistory) => entry.actualTransferTimeMinutes;

    // Sort the transferTime history data in reverse chronological order
    const sortedData = [...transferHistoryData].sort((a, b) => {
        const dateA = getDate(a) ? new Date(getDate(a)!) : new Date(0);
        const dateB = getDate(b) ? new Date(getDate(b)!) : new Date(0);
        return dateB.getTime() - dateA.getTime(); // Most recent dates first
    });

    // Pagination logic
    const totalPages = Math.ceil(sortedData.length / PAGE_SIZE);
    const paginatedData = sortedData.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Utility function to format time as hh:mm
    const formatTime = (datetimeString: string | null | undefined): string => {
        if (!datetimeString) return '';
        
        const date = new Date(datetimeString);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        
        return `${hours}:${minutes}`;
    };


    return (
        <ContainerCard>
            <CardHeader>
                <TypographyStyles.BodyLSemibold color={colors.black}>Transfer history</TypographyStyles.BodyLSemibold>
                <TypographyStyles.BodyLRegular color={colors.grey600}>
                    (last 30 days)
                </TypographyStyles.BodyLRegular>
            </CardHeader>
            <TableContainer>
                <TableHeader>
                    <HeaderItem>Date</HeaderItem>
                    <HeaderItem>Real arrival</HeaderItem>
                    <HeaderItem>Transfer time</HeaderItem>
                    <HeaderItem>Real departure</HeaderItem>
                </TableHeader>
                <TransferHistoryTable>
                    {paginatedData.map((entry, index) => {
                        const realArrival = formatTime(entry.actualArrivalTime);
                        const arrivalCancelled = entry.arrivalCancelled;
                        const transferTime = getTransferTime(entry);
                        const realDeparture = formatTime(entry.actualDepartureTime);
                        const departureCancelled = entry.departureCancelled;

                        // Get display information
                        const { formattedTransferTime, displayColor } = getDisplayInfo(transferTime, arrivalCancelled, departureCancelled);

                        // Use transferTime formatted if formattedTransferTime is null
                        const displayTransferTime = formattedTransferTime || formatDuration(transferTime ?? 0); // Fallback to 0 if transferTime is null
                        const color = displayColor || (transferTime !== null && transferTime !== undefined ? getColor(transferTime, 'transferTimeInMinutes') : colors.grey600);

                        // Determine the display and color for arrival and departure
                        const arrivalText = arrivalCancelled ? 'Cancelled' : realArrival || 'no data';
                        const arrivalColor = arrivalCancelled ? colors.red : (realArrival ? colors.grey800 : colors.grey600);

                        const departureText = departureCancelled ? 'Cancelled' : realDeparture || 'no data';
                        const departureColor = departureCancelled ? colors.red : (realDeparture ? colors.grey800 : colors.grey600);
                        
                        return (
                        <React.Fragment key={index}>
                            <DataRow>
                                <CellItem color={colors.grey800}>
                                        {formatDate(getDate(entry))}
                                </CellItem>
                                <CellItem color={arrivalColor}>
                                    {arrivalText}
                                </CellItem>
                                <CellItem color={color}>
                                    {displayTransferTime}
                                </CellItem>
                                <CellItem color={departureColor}>
                                    {departureText}
                                </CellItem>
                            </DataRow>
                            {index < paginatedData.length - 1 && <Divider />}
                        </React.Fragment>
                        );
                    })}
                </TransferHistoryTable>
            </TableContainer>
            <CardFooter>
                <TypographyStyles.BodyMRegular color={colors.grey600}>Page {currentPage} of {totalPages}</TypographyStyles.BodyMRegular>
            <PaginatorContainer>
                <PrevButton onClick={handlePrevPage} disabled={currentPage === 1} />
                <NextButton onClick={handleNextPage} disabled={currentPage === totalPages} />
                </PaginatorContainer>
            </CardFooter>
        </ContainerCard>
    );
};


export default TransferHistoryCard;